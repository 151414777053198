import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from './index';

export const myMembershipLink = {
  id: 'my-membership',
  label: <FormattedMessage defaultMessage="My membership" />,
  upperCaseLabel: <FormattedMessage defaultMessage="MY MEMBERSHIP" />,
  link: '/dashboard/membership',
  isZenithLink: true,
  mixpanelContext: 'other_membership',
  pageType: PAGE_TYPES.ZENITH
};

export default myMembershipLink;
