import { useEffect, useState } from 'react';

const HIDDEN_STYLE = {
  opacity: 0,
  width: 0,
  height: 0,
  padding: 0,
  margin: 0,
  border: 'none'
};

function Honeypot({
  onGrab
}: {
  onGrab: (value: boolean) => void;
}): React.ReactNode {
  const [checked, setChecked] = useState(false);
  const [filled, setFilled] = useState(false);

  const toggleChecked = () => setChecked(prevState => !prevState);

  const toggleFilled = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilled(!!event.target.value);

  useEffect(() => {
    onGrab(checked || filled);
  }, [checked, filled, onGrab]);

  return (
    <>
      <input
        data-testid="PotMail_TESTID"
        onChange={toggleFilled}
        type="email"
        tabIndex={-1}
        aria-hidden="true"
        style={HIDDEN_STYLE}
      />
      <input
        data-testid="PotCheck_TESTID"
        onChange={toggleChecked}
        type="checkbox"
        aria-hidden="true"
        tabIndex={-1}
        style={HIDDEN_STYLE}
      />
    </>
  );
}

export default Honeypot;
