import { useCallback, useState } from 'react';

import { IconedTitle } from 'components/@tedui/IconedTitle';
import { NewsletterForm } from 'components/NewsletterForm';
import { Link } from 'components/router';
import { Text } from 'components/typography';
import useVideoPlayerStore from 'components/video-player/store';
import { MIXPANEL_PRODUCT } from 'lib/analytics/constants';
import mixpanel from 'lib/analytics/mixpanel';
import { getSystemLanguage } from 'lib/analytics/mixpanel-helpers';
import { useSailthru } from 'lib/hooks/useSailthru';

import EmailIcon from 'icons/EmailIcon';

const FORM_NAME = 'footer TTD subscribe';

const footerNewsletterLabels = {
  subscribed: {
    title: 'You’re subscribed to TED Talks Daily!',
    subtitle: 'See you soon, in your inbox.'
  },
  notSubscribed: {
    title: 'Get the latest talks',
    subtitle:
      'Get a daily email featuring the latest talk, plus a quick mix of trending content.'
  },
  privacyPolicy:
    'By subscribing, you understand and agree that we will store, process and manage your personal information according to our'
};

const FooterNewsletter = (): React.ReactNode => {
  const [subscribed, setSubscribed] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const { title, subtitle } = subscribed
    ? footerNewsletterLabels.subscribed
    : footerNewsletterLabels.notSubscribed;
  const sailthru = useSailthru();
  const autoplayed = useVideoPlayerStore(store => store.autoplayed);

  const handleSuccessSubscription = useCallback(() => {
    setSubscribed(true);
  }, []);

  const handleError = useCallback(() => {
    setWrongEmail(true);
  }, []);

  const onSubscribe = useCallback(
    (newsletterEmail: string) => {
      setWrongEmail(false);
      mixpanel.track('form_submit', {
        product: MIXPANEL_PRODUCT,
        form_name: FORM_NAME,
        system_language: getSystemLanguage(),
        autoplay_state: autoplayed
      });
      mixpanel.track('global_footer_click', {
        event_interaction_context: 'newsletters_subscribe',
        system_language: getSystemLanguage()
      });
      sailthru.signUp({
        email: newsletterEmail,
        newsletterName: 'TED Talks Daily',
        onSuccess: handleSuccessSubscription, // while we don't have the proper solution
        onError: handleError
      });
    },
    [sailthru, handleSuccessSubscription, handleError, autoplayed]
  );

  return (
    <>
      <Text
        data-testid="NewsletterTitle_TESTID"
        className="bold mb-6 font-bold text-white"
        size="m"
      >
        Newsletters
      </Text>
      <IconedTitle
        icon={<EmailIcon className="mr-4 h-6" />}
        color="text-white"
        label={title}
      />
      <Text
        className={`${subscribed ? 'mb-10 mt-4' : 'my-4'} text-white`}
        size="m"
      >
        {subtitle}
      </Text>
      {subscribed ? null : (
        <>
          <NewsletterForm
            location="footer"
            error={wrongEmail}
            onSubscribe={onSubscribe}
          />
          <Text className="mb-10 text-textTertiary-onDark" size="m">
            {footerNewsletterLabels.privacyPolicy}{' '}
            <Link
              href="/about/our-organization/our-policies-terms/privacy-policy"
              className="underline"
              onClick={() => {
                mixpanel.track('global_footer_click', {
                  event_interaction_context: 'newsletters_privacy-policy',
                  system_language: getSystemLanguage()
                });
              }}
            >
              Privacy Policy
            </Link>
          </Text>
        </>
      )}
    </>
  );
};

export default FooterNewsletter;
