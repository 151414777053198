import ContentContainer from 'components/content-container';
import Grid from 'components/grid';
import Col from 'components/grid/col';
import { Text } from 'components/typography';

import FooterColumnListItem from './FooterColumnListItem';
import { FooterNewsletter } from './Newsletter';
import DownloadTedApp from './footer-download-app';
import FooterPromo from './footer-promo';
import { FooterSocialLink } from './footer-social';

import TedLogo from 'icons/TedLogoIcon';

import { useLayoutStore } from 'components/layout/store';

import { PAGE_TYPES } from '../Navigation/data';

const topFooterLinks = [
  {
    label: 'Explore',
    items: [
      {
        label: 'TEDx',
        url: '/about/programs-initiatives/tedx-program',
        mixpanelContext: 'explore_tedx',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        label: 'TED Fellows',
        url: 'https://fellows.ted.com',
        mixpanelContext: 'explore_ted-fellows',
        pageType: PAGE_TYPES.EXTERNAL
      },
      {
        label: 'TED Ed',
        url: '/about/programs/ted-ed',
        mixpanelContext: 'explore_ted-ed',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        label: 'TED Translators',
        url: '/about/programs-initiatives/ted-translators',
        mixpanelContext: 'explore_ted-translators',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        label: 'TED Institute',
        url: '/about/programs-initiatives/ted-institute',
        mixpanelContext: 'explore_ted-institute',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        label: 'The Audacious Project',
        url: '/about/programs-initiatives/the-audacious-project',
        mixpanelContext: 'explore_the-audacious-project',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        label: 'TED@Work',
        url: 'https://tedatwork.ted.com',
        mixpanelContext: 'explore_ted@work',
        pageType: PAGE_TYPES.EXTERNAL
      },
      {
        label: 'Podcasts',
        url: '/podcasts',
        mixpanelContext: 'explore_podcasts',
        pageType: PAGE_TYPES.ZENITH
      },
      {
        label: 'TED Blog',
        url: 'https://blog.ted.com',
        mixpanelContext: 'explore_ted-blog',
        pageType: PAGE_TYPES.EXTERNAL
      },
      {
        label: 'More ways to get TED',
        url: '/about/programs-initiatives/ted-talks/ways-to-get-ted-talks',
        mixpanelContext: 'explore_more-ways-to-get-ted',
        pageType: PAGE_TYPES.PRISMIC
      }
    ]
  },
  {
    label: 'Our community',
    items: [
      {
        label: 'TED Speakers',
        url: '/people/speakers',
        mixpanelContext: 'community_ted-speakers',
        pageType: PAGE_TYPES.PAGE_BUILDER
      },
      {
        label: 'TED Fellows',
        url: 'https://fellows.ted.com/directory',
        mixpanelContext: 'community_ted-fellows',
        pageType: PAGE_TYPES.EXTERNAL
      },
      {
        label: 'TED Translators',
        url: '/people/translators',
        mixpanelContext: 'community_ted-translators',
        pageType: PAGE_TYPES.PAGE_BUILDER
      },
      {
        label: 'TEDx Organizers',
        url: '/people/tedx',
        mixpanelContext: 'community_tedx-organizers',
        pageType: PAGE_TYPES.PAGE_BUILDER
      },
      {
        label: 'TED Community',
        url: '/people',
        mixpanelContext: 'community_ted-community',
        pageType: PAGE_TYPES.PAGE_BUILDER
      }
    ]
  }
];

const tedSocialLinks = {
  label: 'Follow TED',
  items: [
    {
      Icon: 'linkedin',
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/company/ted-conferences/',
      mixpanelContext: 'follow-ted_linkedin',
      pageType: PAGE_TYPES.EXTERNAL
    },
    {
      Icon: 'twitter',
      label: 'Twitter',
      url: 'https://twitter.com/tedtalks',
      mixpanelContext: 'follow-ted_twitter',
      pageType: PAGE_TYPES.EXTERNAL
    },
    {
      Icon: 'instagram',
      label: 'Instagram',
      url: 'https://instagram.com/ted',
      mixpanelContext: 'follow-ted_instagram',
      pageType: PAGE_TYPES.EXTERNAL
    },
    {
      Icon: 'youtube',
      label: 'YouTube',
      url: 'https://www.youtube.com/ted',
      mixpanelContext: 'follow-ted_youtube',
      pageType: PAGE_TYPES.EXTERNAL
    },
    {
      Icon: 'facebook',
      label: 'Facebook',
      url: 'https://www.facebook.com/TED',
      mixpanelContext: 'follow-ted_facebook',
      pageType: PAGE_TYPES.EXTERNAL
    }
  ]
};

const footerPromoLinks = {
  membership: {
    label: 'Become a TED Member',
    url: '/membership?utm_medium=website&utm_source=footer&utm_campaign=membership-ted',
    text: 'TED Members help billions of people worldwide access inspiring ideas. Plus, they get exclusive benefits like invite-only events with speakers. Join today for a brighter future - and a better you.',
    mixpanelContext: 'member_become-a-ted-member',
    pageType: PAGE_TYPES.ZENITH
  },
  membershipDashboard: {
    label: 'Manage my TED Membership',
    url: '/dashboard/membership',
    text: "Thank you for being a valued member of the TED membership community. Your contribution supports TED's mission to launch ideas that shape our future.",
    mixpanelContext: 'member_manage-my-ted-membership',
    pageType: PAGE_TYPES.ZENITH
  }
};

const FooterTop = () => {
  const { showLinksFooter } = useLayoutStore();
  return (
    <ContentContainer addMaxWidth={false}>
      <TedLogo variant="white" className="my-12 h-6" />
      {showLinksFooter && (
        <Grid eightColGrid={false} className="pr-5">
          <Col sm={4} md={6} xl={4}>
            <nav>
              <Grid eightColGrid={false}>
                {topFooterLinks.map(({ label, items }, i) => {
                  return (
                    <Col
                      sm={8}
                      md={6}
                      key={label}
                      wrapperClassName={
                        i > 0
                          ? 'border-t-thin border-textTertiary-onDark w-full lg:w-auto pt-10 md:border-none md:pt-0'
                          : null
                      }
                      className="pb-4"
                    >
                      <div className="lg:pb-0 lg:pt-0">
                        <Text size="m" className="mb-6 font-bold text-white">
                          {label}
                        </Text>
                        <Grid tagName="ul">
                          {items.map(
                            ({
                              label: sublabel,
                              url,
                              mixpanelContext,
                              pageType
                            }) => (
                              <FooterColumnListItem
                                key={sublabel}
                                label={label}
                                sublabel={sublabel}
                                url={url}
                                mixpanelContext={mixpanelContext}
                                pageType={pageType}
                                UNSAFE_className="!text-tui-sm text-white"
                              />
                            )
                          )}
                        </Grid>
                      </div>
                    </Col>
                  );
                })}
              </Grid>
            </nav>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            xl={4}
            wrapperClassName="w-full border-t-thin border-textTertiary-onDark pt-10 md:border-none md:pt-0 pr-6 sm:pr-0 lg: "
          >
            <FooterNewsletter />
          </Col>
          <Col sm={0} xl={1} />
          <Col
            sm={12}
            xl={3}
            wrapperClassName="border-t-thin border-textTertiary-onDark pt-10 xl:border-none xl:pt-0"
            className="flex flex-row pb-10 xl:flex-col xl:pl-4"
          >
            <Grid>
              <Col sm={12} md={6} xl={12} className="pr-15 md:mr-5">
                <FooterPromo promoData={footerPromoLinks} />
              </Col>
              <Col
                sm={12}
                md={6}
                xl={12}
                wrapperClassName="border-t-thin border-textTertiary-onDark w-full lg:w-auto pt-10 md:border-none md:pt-0"
              >
                <Text className="bold mb-6 font-bold text-white" size="m">
                  {tedSocialLinks.label}
                </Text>
                <div className="mb-10 flex flex-row gap-5">
                  {tedSocialLinks.items.map(
                    ({ url, Icon, label, mixpanelContext }) => (
                      <FooterSocialLink
                        url={url}
                        Icon={
                          Icon as
                            | 'linkedin'
                            | 'twitter'
                            | 'instagram'
                            | 'youtube'
                            | 'facebook'
                        }
                        key={url}
                        label={label}
                        mixpanelContext={mixpanelContext}
                      />
                    )
                  )}
                </div>
                <div className="flex-col sm:hidden md:flex">
                  <DownloadTedApp />
                </div>
              </Col>
              <Col
                sm={12}
                wrapperClassName="sm:border-t-thin border-textTertiary-onDark w-full lg:w-auto sm:pt-10 md:border-none md:pt-0 md:hidden"
              >
                <DownloadTedApp />
              </Col>
            </Grid>
          </Col>
        </Grid>
      )}
    </ContentContainer>
  );
};

export default FooterTop;
