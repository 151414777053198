import SkeletonImage from './image';
import SkeletonTitle from './title';
import SkeletonContent from './content';

export type LinesBlockProps = {
  numberOfLines?: number;
  className?: string;
};

export type ImageProps = {
  ratio?: [number, number];
  className?: string;
};

export default {
  Image: SkeletonImage,
  Title: SkeletonTitle,
  Content: SkeletonContent
};
