import cx from 'classnames';
import { Link } from 'components/router';
import { Text } from 'components/typography';
import mixpanel from 'lib/analytics/mixpanel';
import { useLocalStorage } from 'lib/hooks/useLocalStorage';
import { useEffect, useId, useRef, useState } from 'react';
import { PAGE_TYPES, topNavigationMenus } from './data';

const MenuButtonList = () => {
  const id = useId();
  const [, setNavigationContext] = useLocalStorage('navigation_context', '');
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const [hoveredMenuIndex, setHoveredMenuIndex] = useState<number | null>(null);
  const menuRefs = useRef<(HTMLDivElement | null)[]>([]);
  const hoverTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        openMenuIndex !== null &&
        menuRefs.current[openMenuIndex] &&
        !menuRefs.current[openMenuIndex]?.contains(event.target as Node)
      ) {
        setOpenMenuIndex(null);
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenMenuIndex(null);
        setHoveredMenuIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
      if (hoverTimeoutRef.current) {
        window.clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, [openMenuIndex]);

  const handleMouseEnter = (index: number) => {
    if (hoverTimeoutRef.current) {
      window.clearTimeout(hoverTimeoutRef.current);
    }
    setHoveredMenuIndex(index);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      window.clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = window.setTimeout(() => {
      setHoveredMenuIndex(null);
    }, 100); // Small delay to handle gap between elements
  };

  const menuButtonClasses = cx(
    'menuButton outline-inside border-4 px-3 py-5 group-hover:bg-gray-50 dark:rounded-sm dark:py-4 dark:group-hover:bg-black dark:group-hover:bg-gray-800'
  );

  const optionClasses = cx(
    'menuItem block px-8 py-4 first:pt-8 last:pb-8 hover:bg-gray-50 dark:hover:bg-gray-700'
  );

  const isMenuVisible = (index: number) =>
    openMenuIndex === index || hoveredMenuIndex === index;

  return (
    <ul className="flex">
      {topNavigationMenus.map((menu, index) => (
        <li
          className="navigation-header-menu-wrapper group relative"
          key={menu.mixpanelContext}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <button
            className={menuButtonClasses}
            onClick={event => {
              event.preventDefault();
              setOpenMenuIndex(openMenuIndex === index ? null : index);
            }}
            aria-expanded={isMenuVisible(index)}
            aria-haspopup="true"
            aria-controls={`${id}-menu-${index}-list`}
          >
            <Text size="s" className="font-bold text-gray-900 dark:text-white">
              {menu.label}
            </Text>
          </button>
          <div
            ref={el => {
              menuRefs.current[index] = el;
            }}
            role="menu"
            id={`${id}-menu-${index}`}
          >
            <div
              id={`${id}-menu-${index}-list`}
              role="menu"
              className={cx(
                'absolute left-1/2 top-full z-10 -translate-x-1/2 transform overflow-hidden rounded-md border-thin border-gray-300 bg-white shadow-xl outline-none dark:border-x-[0px] dark:border-b-[0px] dark:border-t-thickest dark:border-t-black dark:bg-gray-800',
                {
                  hidden: !isMenuVisible(index)
                }
              )}
            >
              {menu.submenus.map(submenu => {
                const isExternalLink =
                  submenu.pageType === PAGE_TYPES.EXTERNAL ||
                  submenu.pageType === PAGE_TYPES.PAGE_BUILDER;

                const Component = isExternalLink ? 'a' : Link;

                return (
                  <Component
                    href={submenu.link}
                    key={submenu.link}
                    className={optionClasses}
                    role="menuitem"
                    onClick={() => {
                      setNavigationContext(
                        `Header:${menu.label.props?.defaultMessage[0]?.value}:${submenu.label.props?.defaultMessage[0]?.value}`
                      );
                      mixpanel.track('global_nav_click', {
                        system_language: navigator.language || null,
                        event_interaction_context: submenu.mixpanelContext
                      });
                      setOpenMenuIndex(null);
                      setHoveredMenuIndex(null);
                    }}
                  >
                    <Text size="l" className="w-60 font-bold dark:text-white">
                      {submenu.label}
                    </Text>
                    <Text
                      size="m"
                      className="w-60 text-gray-700 dark:text-[#CFCFD0]"
                    >
                      {submenu.description}
                    </Text>
                  </Component>
                );
              })}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MenuButtonList;
