import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { NavUserInformationQuery, useNavUserInformationQuery } from 'api';
import { FundraiseUpRevenueTracking } from 'components/FundraiseUp';
import { client } from 'f';
import { useAuthenticated } from 'lib/auth';
import { useDidMount } from 'lib/hooks/useDidMount';
import { breakpoints } from '../../../design-tokens/constants';

import NavigationNav from './Nav';
import type NavigationProps from './Navigation.props';
import { LinkList, UserData } from './User/UserLinkList/UserLinkList.props';
import { PAGE_TYPES, userLinks } from './data';

const MenuModal = dynamic(() => import('./MenuModal'));
const UserModal = dynamic(() => import('./User/UserModal'));

const SEARCH_PATH = '/search?q=';

function NavigationHeader({
  isDarkMode = false
}: NavigationProps): React.ReactNode {
  const isLogged = useAuthenticated();
  const { loading, data } = useNavUserInformationQuery({
    ssr: true,
    skip: !isLogged
  });
  const isMounted = useDidMount();

  const loggedIn = useMemo(() => isLogged && isMounted, [isLogged, isMounted]);
  const isLoading = useMemo(() => loading && isMounted, [loading, isMounted]);

  const user = data?.viewer as NavUserInformationQuery['viewer'];
  const userIsMember = user?.isMember;

  const [navigationModalOpen, setNavigationModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const userButtonRef = useRef<HTMLButtonElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const [inputValue, setInputValue] = useState('');
  const [navSearchFocused, setNavSearchFocused] = useState(false);

  useEffect(() => {
    function handleResize(e: UIEvent) {
      const target = e.target as Window;
      if (target.innerWidth >= breakpoints.lg) {
        setNavigationModalOpen(false);
        setUserModalOpen(false);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavigationModalToggle = useCallback((modalOpen: boolean) => {
    setNavigationModalOpen(modalOpen);
    if (!modalOpen) requestAnimationFrame(() => menuButtonRef.current?.focus());
  }, []);

  const handleHamburgerMenuClick = useCallback(
    (modalOpen: boolean) => {
      setUserModalOpen(false);
      handleNavigationModalToggle(modalOpen);
    },
    [handleNavigationModalToggle]
  );

  const handleUserModalToggle = useCallback((modalOpen: boolean) => {
    setUserModalOpen(modalOpen);
    if (!modalOpen) requestAnimationFrame(() => userButtonRef.current?.focus());
  }, []);

  const handleProfileButtonClick = useCallback(
    (modalOpen: boolean) => {
      setNavigationModalOpen(false);
      handleUserModalToggle(modalOpen);
    },
    [handleUserModalToggle]
  );

  const handleSubmit = useCallback(() => {
    if (inputValue !== '') {
      const currentLocation = 'https://www.ted.com';
      document.location.href = `${currentLocation}/${SEARCH_PATH}${inputValue}`;
    }
  }, [inputValue]);

  const isRevenue = useCallback(() => {
    if (client) {
      return (
        ((document.querySelector('.price-control') as HTMLInputElement)
          ?.value as unknown as number) || null
      );
    }
    return null;
  }, []);

  const userLinkList: LinkList[] = [...userLinks];
  // TODO: add Conferences, TEDx events, Your ted-ed activity when api is ready
  if (userIsMember) {
    userLinkList.splice(1, 0, {
      id: 'membership',
      label: <FormattedMessage defaultMessage="Membership" />,
      link: '/dashboard/membership',
      isZenithLink: true,
      mixpanelContext: 'profile_membership',
      pageType: PAGE_TYPES.ZENITH
    });
  }

  return (
    <div className={cx('z-10 w-full', { dark: isDarkMode })}>
      <NavigationNav
        handleHamburgerMenuClick={handleHamburgerMenuClick}
        handleProfileButtonClick={handleProfileButtonClick}
        setNavSearchFocused={setNavSearchFocused}
        menuButtonRef={menuButtonRef}
        userButtonRef={userButtonRef}
        userIsMember={userIsMember as boolean}
        loggedIn={loggedIn}
        isLoading={isLoading}
        inputValue={inputValue}
        setInputValue={e => setInputValue(e.target.value)}
        handleSubmit={handleSubmit}
        navSearchFocused={navSearchFocused}
        userLinkList={userLinkList}
        userModalOpen={userModalOpen}
      />
      <MenuModal
        navigationModalOpen={navigationModalOpen}
        setNavigationModalOpen={setNavigationModalOpen}
        userIsMember={userIsMember as boolean}
        handleSubmit={handleSubmit}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
      {loggedIn && (
        <UserModal
          userModalOpen={userModalOpen}
          setUserModalOpen={setUserModalOpen}
          user={user as UserData}
          isLoading={isLoading}
          userLinkList={userLinkList}
        />
      )}
      <FundraiseUpRevenueTracking revenue={isRevenue() || undefined} />
    </div>
  );
}

export default NavigationHeader;
