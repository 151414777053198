import cx from 'classnames';

import type { JSX } from 'react';

type ColProps = {
  tagName?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  offsetSm?: number;
  offsetMd?: number;
  offsetLg?: number;
  offsetXl?: number;
  className?: string;
  wrapperClassName?: string;
  customSmMdPadding?: string;
  forceSmValue?: boolean;
};

function Col({
  tagName,
  children,
  className,
  wrapperClassName,
  sm,
  md,
  lg,
  xl,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  forceSmValue,
  customSmMdPadding
}: ColProps): React.ReactNode {
  const smallSpan = forceSmValue ? sm : sm || 1;
  const colClasses = cx(
    customSmMdPadding ?? 'pr-4 md:pr-5',
    `col-sm-${smallSpan}`,
    wrapperClassName,
    {
      [`col-md-${md}`]: md,
      [`col-lg-${lg}`]: lg,
      [`col-xl-${xl}`]: xl,
      [`col-sm-offset-${offsetSm}`]: offsetSm || offsetSm === 0,
      [`col-md-offset-${offsetMd}`]: offsetMd || offsetMd === 0,
      [`col-lg-offset-${offsetLg}`]: offsetLg || offsetLg === 0,
      [`col-xl-offset-${offsetXl}`]: offsetXl || offsetXl === 0
    }
  );
  const defaultTagName = 'div';
  const Tag = tagName || defaultTagName;

  return (
    <Tag className={colClasses}>
      <div className={className}>{children}</div>
    </Tag>
  );
}

export default Col;
