import cx from 'classnames';
import { Link } from 'components/router';
import { Text } from 'components/typography';
import * as React from 'react';

import Chevron from 'icons/ChevronIcon';

type ChevronLinkProps = {
  text: string;
  url: string;
  disabled?: boolean;
  newTab?: boolean;
  className?: string;
  onClick?: () => void;
};

const ChevronLink = ({
  ref,
  text,
  url,
  disabled,
  className,
  onClick,
  newTab,
  ...props
}: ChevronLinkProps & {
  ref?: React.RefObject<HTMLAnchorElement | null>;
}) => {
  const chevronLinkClasses = cx(
    'flex max-w-max font-bold',
    {
      'text-gray-700 opacity-50': disabled
    },
    className
  );

  const Tag = React.useMemo(() => {
    if (disabled) {
      return 'span';
    }
    if (newTab) {
      return 'a';
    }
    return Link;
  }, [disabled, newTab]);

  return (
    <Tag
      {...(newTab ? { target: '_blank' } : {})}
      {...(!disabled ? { href: url, onClick } : {})}
      className={chevronLinkClasses}
      ref={ref}
      {...props}
    >
      <Text size="m" className="mr-1">
        {text}
      </Text>
      <div className="pt-05">
        <Chevron variant="link" className="h-4 w-4" />
      </div>
    </Tag>
  );
};

export default ChevronLink;
