import { crush } from '@tedconf/js-crushinator-helpers';
import { Ratio } from 'components/@tedui';
import UserImageProps from './UserImage.props';

export default function UserImage({
  className,
  isLoading,
  userImage
}: UserImageProps): React.ReactNode {
  return (
    <div className={className}>
      <Ratio ratio={[1, 1]}>
        <div
          style={
            isLoading
              ? {}
              : {
                  backgroundImage: `url(${crush(userImage)})`
                }
          }
          className="size-full rounded-full bg-gray-300 bg-cover bg-center"
        />
      </Ratio>
    </div>
  );
}
