import { IS_PROD } from 'lib/constants';
import Head from 'next/head';
import { tokens } from 'theme';

type TEDHeadProps = {
  pageTitle?: string;
};

const TEDHead = ({ pageTitle }: TEDHeadProps): React.ReactNode => {
  const TEDRED = tokens.colors.TEDRed[0];
  const title = pageTitle ?? 'TED: Ideas change everything';

  return (
    <Head>
      <title>{title}</title>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@tedtalks" />
      <meta name="twitter:app:id:iphone" content="376183339" />
      <meta name="twitter:app:id:ipad" content="376183339" />
      <meta name="twitter:app:id:googleplay" content="com.ted.android" />
      <meta property="al:android:app_name" content="TED" />
      <meta property="al:android:package" content="com.ted.android" />
      <meta property="al:ios:app_name" content="TED" />
      <meta property="al:ios:app_store_id" content="376183339" />
      <meta name="twitter:app:name:ipad" content="TED" />
      <meta name="twitter:app:name:iphone" content="TED" />
      <meta name="twitter:app:name:googleplay" content="TED" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="MobileOptimized" content="320" />
      <meta name="apple-mobile-web-app-title" content="TED Talks" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="application-name" content="TED Talks" />
      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, initial-scale=1.0, viewport-fit=cover"
      />
      <meta
        name="msapplication-config"
        content="https://www.ted.com/browserconfig.xml"
      />
      <meta name="msapplication-TileColor" content="#000000" />
      <link
        rel="apple-touch-icon"
        href="https://pa.tedcdn.com/apple-touch-icon.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        href="https://pa.tedcdn.com/apple-touch-icon-precomposed.png"
      />
      <meta httpEquiv="cleartype" content="on" />
      <link rel="icon" href="/favicon.ico" sizes="32x32" />
      <link rel="icon" href="/icon.svg" type="image/svg+xml" />
      <meta
        property="fb:app_id"
        content={`${process.env.NEXT_PUBLIC_FB_APP_ID}`}
      />
      <meta name="rss-feed" content="https://www.ted.com/feeds/talks.rss" />
      <link
        rel="mask-icon"
        href="https://pa.tedcdn.com/mask-icon.svg"
        color={TEDRED}
        sizes="any"
      />
      <meta name="theme-color" content={TEDRED} />
      {!IS_PROD && <meta name="robots" content="noindex, nofollow" />}
    </Head>
  );
};

export default TEDHead;
