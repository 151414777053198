import cx from 'classnames';
import { ChangeEventHandler, RefObject, useCallback, useMemo } from 'react';

import { BaseButtonType } from 'components/base-button';
import TedLogo from 'icons/TedLogoIcon';
import LinkButton from 'components/link-button';
import { Link } from 'components/router';
import { Text } from 'components/typography';
import mixpanel from 'lib/analytics/mixpanel';

import { useNavUserInformationQuery } from 'api';
import { Icon } from 'components/@tedui';
import { useLayoutStore } from 'components/layout/store';
import { useAuthenticated } from 'lib/auth';
import { useIntl } from 'react-intl';
import { membershipLink, myMembershipLink, signInLink } from './data';
import MenuButtonList from './MenuButtonList';
import NavigationSearch from './Search';
import { UserImage } from './User';
import { Badge } from 'components/@tedui';

import {
  LinkList,
  type UserData
} from './User/UserLinkList/UserLinkList.props';
import UserMenu from './User/UserMenu';

type NavigationNavProps = {
  handleHamburgerMenuClick: (modalOpen: boolean) => void;
  handleProfileButtonClick: (modalOpen: boolean) => void;
  setNavSearchFocused: (focused: boolean) => void;
  menuButtonRef: RefObject<HTMLButtonElement | null>;
  userButtonRef: RefObject<HTMLButtonElement | null>;
  userIsMember: boolean;
  loggedIn: boolean;
  isLoading: boolean;
  inputValue: string;
  setInputValue: ChangeEventHandler<HTMLInputElement>;
  handleSubmit: () => void;
  navSearchFocused: boolean;
  userLinkList: LinkList[];
  userModalOpen: boolean;
};

const SEARCH_ID = 'navigation-search';

const NavigationNav = ({
  handleHamburgerMenuClick,
  handleProfileButtonClick,
  setNavSearchFocused,
  menuButtonRef,
  userButtonRef,
  userIsMember,
  loggedIn,
  isLoading,
  inputValue,
  setInputValue,
  handleSubmit,
  navSearchFocused,
  userLinkList,
  userModalOpen
}: NavigationNavProps): React.ReactNode => {
  const { showMenuLinks } = useLayoutStore();
  const isLoggedIn = useAuthenticated();
  const { data } = useNavUserInformationQuery({
    ssr: true,
    skip: !isLoggedIn
  });

  const user = data?.viewer;

  const intl = useIntl();
  const profileAriaLabel = intl.formatMessage({
    defaultMessage: 'Profile'
  });

  const closeAriaLabel = intl.formatMessage({
    defaultMessage: 'Close'
  });

  const userButtonClasses = useMemo(
    () =>
      cx(
        'flex h-14 w-fit transform items-center gap-1 lg:-mr-15 lg:ml-4 lg:transform-none lg:p-3',
        {
          'translate-x-25 p-3': userModalOpen,
          'translate-x-15 p-15': !userModalOpen
        }
      ),
    [userModalOpen]
  );

  const userImageClasses = useMemo(
    () =>
      cx('size-10', {
        block: !userModalOpen,
        'hidden lg:block': userModalOpen
      }),
    [userModalOpen]
  );

  const navClasses = useMemo(
    () =>
      cx('flex items-center transition-opacity', {
        'opacity-100': !navSearchFocused,
        'opacity-0': navSearchFocused
      }),
    [navSearchFocused]
  );

  const getMembershipLabel = useCallback((size?: 'uppercase') => {
    return size === 'uppercase'
      ? membershipLink.fundRaiseUpUpperCaseLabel
      : membershipLink.fundRaiseUpLabel;
  }, []);

  return (
    <div className="h-14 bg-white px-5 shadow-md dark:bg-black dark:shadow-none md:px-10 lg:px-6">
      <div className="relative flex items-center justify-between">
        {showMenuLinks && (
          <button
            type="button"
            className="flex -translate-x-25 transform items-center justify-center p-25 dark:text-white lg:hidden"
            onClick={() => handleHamburgerMenuClick(!navSearchFocused)}
            ref={menuButtonRef}
            aria-label={navSearchFocused ? 'Close' : 'Menu'}
            data-testid="main-navigation__menu-button"
          >
            <Icon
              iconName={navSearchFocused ? 'x' : 'menu'}
              className="text-tui-3xl"
            />
          </button>
        )}
        <div className="relative">
          <Link
            href="/"
            className="outline-inside flex items-center py-4"
            onClick={() =>
              mixpanel.track('global_nav_click', {
                system_language: navigator?.language || null,
                event_interaction_context: 'other_logo'
              })
            }
            aria-label="TED Homepage - Ideas change everything"
            title="Return to TED Homepage"
          >
            <TedLogo className="h-6 lg:mr-3" ariaHidden />
            <Text
              size="m"
              className="hidden text-gray-500 dark:text-white lg:block"
            >
              Ideas change everything
            </Text>
          </Link>
        </div>
        {showMenuLinks && (
          <div className="flex items-center lg:mr-14">
            <div className={navClasses}>
              <nav className="hidden lg:block">
                <MenuButtonList />
              </nav>
              {!loggedIn && (
                <Link
                  className="outline-inside flex translate-x-2 transform items-center p-2 hover:underline dark:my-1 dark:rounded-sm dark:hover:bg-gray-800 dark:hover:text-gray-300 lg:transform-none lg:px-3 lg:py-5 lg:hover:no-underline dark:lg:py-4"
                  href={signInLink.link}
                  onClick={() => {
                    mixpanel.track('global_nav_click', {
                      system_language: navigator.language || null,
                      event_interaction_context: signInLink.mixpanelContext
                    });
                  }}
                >
                  <Text
                    size="s"
                    className="font-bold text-black dark:text-white dark:hover:hover:text-gray-300 lg:text-gray-900 dark:lg:text-white"
                  >
                    {signInLink.label}
                  </Text>
                </Link>
              )}
              <LinkButton
                className="vwo-membership-standard ml-3 hidden lg:block"
                id="vwo-membership-standard"
                small
                href={
                  userIsMember ? myMembershipLink.link : membershipLink.link
                }
                variant={
                  userIsMember
                    ? BaseButtonType.Tertiary
                    : BaseButtonType.Primary
                }
                text={
                  userIsMember
                    ? myMembershipLink.upperCaseLabel
                    : getMembershipLabel('uppercase')
                }
                onClick={() => {
                  mixpanel.track('global_nav_click', {
                    system_language: navigator.language || null,
                    event_interaction_context: userIsMember
                      ? myMembershipLink.mixpanelContext
                      : membershipLink.mixpanelContext
                  });
                }}
              />
              {loggedIn && (
                <UserMenu
                  userButtonClasses={userButtonClasses}
                  userImageClasses={userImageClasses}
                  userButtonRef={userButtonRef}
                  isLoading={isLoading}
                  user={user as UserData}
                  userLinkList={userLinkList}
                />
              )}
            </div>
            <div className="lg:hidden">
              {loggedIn && (
                <button
                  type="button"
                  onClick={() => handleProfileButtonClick(!userModalOpen)}
                  ref={userButtonRef}
                  className={userButtonClasses}
                  aria-label={userModalOpen ? closeAriaLabel : profileAriaLabel}
                >
                  {userModalOpen && (
                    <Icon
                      iconName="x"
                      className="text-tui-3xl dark:text-white"
                    />
                  )}
                  <Badge
                    backgroundColor="red-500"
                    testId="avatar-mobile-badge"
                    text="New"
                  />
                  <UserImage
                    className={userImageClasses}
                    isLoading={isLoading}
                    userImage={
                      user?.avatar?.url || user?.avatar?.generatedUrl || ''
                    }
                  />
                </button>
              )}
            </div>
            <div className="absolute right-0 hidden lg:block">
              <NavigationSearch
                searchId={SEARCH_ID}
                onChange={setInputValue}
                onSubmit={handleSubmit}
                value={inputValue}
                navSearchFocused={navSearchFocused}
                onFocus={() => setNavSearchFocused(true)}
                onBlur={() => setNavSearchFocused(false)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationNav;
