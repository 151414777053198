import type { JSX } from 'react';

type EmailIconProps = {
  className?: string;
};

const EmailIcon = (props: EmailIconProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      className={className || ''}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1694_78433)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4.75C3.31421 4.75 2.75 5.31421 2.75 6V18C2.75 18.6858 3.31421 19.25 4 19.25H20C20.6858 19.25 21.25 18.6858 21.25 18V6C21.25 5.31421 20.6858 4.75 20 4.75H4ZM1.25 6C1.25 4.48579 2.48579 3.25 4 3.25H20C21.5142 3.25 22.75 4.48579 22.75 6V18C22.75 19.5142 21.5142 20.75 20 20.75H4C2.48579 20.75 1.25 19.5142 1.25 18V6Z"
          fill="#B5B5B5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.38564 5.56991C1.62318 5.23057 2.09082 5.14804 2.43016 5.38558L12.0001 12.0845L21.57 5.38558C21.9093 5.14804 22.377 5.23057 22.6145 5.56991C22.852 5.90924 22.7695 6.37689 22.4302 6.61443L12.4302 13.6144C12.1719 13.7952 11.8282 13.7952 11.57 13.6144L1.56997 6.61443C1.23063 6.37689 1.1481 5.90924 1.38564 5.56991Z"
          fill="#B5B5B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1694_78433">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EmailIcon;
