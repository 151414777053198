import { crush } from '@tedconf/js-crushinator-helpers';
import cx from 'classnames';
import { useMemo } from 'react';

import Image from 'components/image';

type AvatarProps = Partial<React.AllHTMLAttributes<HTMLImageElement>> & {
  src?: string;
  alt?: string;
  imgWidth?: number;
  hasBackgroundColor?: boolean;
  className?: string;
};

const Avatar = ({
  src = '',
  alt = '',
  imgWidth,
  hasBackgroundColor = true,
  className
}: AvatarProps): React.ReactNode => {
  const url = useMemo(
    () =>
      crush(src, {
        width: imgWidth
      }),
    [src, imgWidth]
  );
  return (
    <div className={className}>
      <Image
        alt={alt}
        src={url}
        height={imgWidth}
        width={imgWidth}
        className={cx('size-full rounded-full object-cover object-center', {
          'bg-[#717171]': hasBackgroundColor
        })}
        sizes="200px"
      />
    </div>
  );
};

export default Avatar;
