import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from '.';

export const fullUrl = 'https://www.ted.com';
const topNavigationMenus = [
  {
    id: 'watch',
    label: <FormattedMessage defaultMessage="WATCH" />,
    mixpanelContext: 'watch',
    submenus: [
      {
        id: 'ted-talks',
        label: <FormattedMessage defaultMessage="TED Talks" />,
        description: (
          <FormattedMessage defaultMessage="Browse the library of TED talks and speakers" />
        ),
        link: '/talks',
        mixpanelContext: 'watch_index',
        pageType: PAGE_TYPES.ZENITH
      },
      {
        id: 'playlists',
        label: <FormattedMessage defaultMessage="Playlists" />,
        description: (
          <FormattedMessage defaultMessage="100+ collections of TED Talks, for curious minds" />
        ),
        link: '/playlists',
        mixpanelContext: 'watch_playlists',
        pageType: PAGE_TYPES.ZENITH
      },
      {
        id: 'ted-series',
        label: <FormattedMessage defaultMessage="TED Series" />,
        description: (
          <FormattedMessage defaultMessage="Go deeper into fascinating topics with original video series from TED" />
        ),
        link: '/series',
        mixpanelContext: 'watch_series',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'ted-ed',
        label: <FormattedMessage defaultMessage="TED-Ed videos" />,
        description: (
          <FormattedMessage defaultMessage="Watch, share and create lessons with TED-Ed" />
        ),
        link: '/watch/ted-ed',
        mixpanelContext: 'watch_ted-ed',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'tedx-talks',
        label: <FormattedMessage defaultMessage="TEDx Talks" />,
        description: (
          <FormattedMessage defaultMessage="Talks from independently organized local events" />
        ),
        link: '/watch/tedx-talks',
        mixpanelContext: 'watch_tedx',
        pageType: PAGE_TYPES.PRISMIC
      }
    ]
  },
  {
    id: 'discover',
    label: <FormattedMessage defaultMessage="DISCOVER" />,
    mixpanelContext: 'discover',
    submenus: [
      {
        id: 'topics',
        label: <FormattedMessage defaultMessage="Topics" />,
        description: (
          <FormattedMessage defaultMessage="Explore TED offerings by topic" />
        ),
        link: '/topics',
        mixpanelContext: 'discover_topics',
        pageType: PAGE_TYPES.ZENITH
      },
      {
        id: 'podcasts',
        label: <FormattedMessage defaultMessage="Podcasts" />,
        description: (
          <FormattedMessage defaultMessage="Explore the TED Audio Collective" />
        ),
        link: 'https://audiocollective.ted.com',
        mixpanelContext: 'discover_audiocollective',
        pageType: PAGE_TYPES.EXTERNAL
      },
      {
        id: 'ideas-blog',
        label: <FormattedMessage defaultMessage="Ideas Blog" />,
        description: (
          <FormattedMessage defaultMessage="Our daily coverage of the world of ideas" />
        ),
        link: 'https://ideas.ted.com',
        mixpanelContext: 'discover_ideas',
        pageType: PAGE_TYPES.EXTERNAL
      },
      {
        id: 'newsletters',
        label: <FormattedMessage defaultMessage="Newsletters" />,
        description: (
          <FormattedMessage defaultMessage="Inspiration delivered straight to your inbox" />
        ),
        link: '/newsletters',
        mixpanelContext: 'discover_newsletters',
        pageType: PAGE_TYPES.ZENITH
      },
      {
        id: 'ted-games',
        label: <FormattedMessage defaultMessage="TED Games" />,
        description: (
          <FormattedMessage defaultMessage="Innovative games that challenge you to think differently" />
        ),
        link: '/games',
        mixpanelContext: 'discover_games',
        // Pagetype is Zenith here, because this is a custom page created in Zenith that uses Prismic data
        // And not part of the whole Prismic Migration
        pageType: PAGE_TYPES.ZENITH
      }
    ]
  },
  {
    id: 'attend',
    label: <FormattedMessage defaultMessage="ATTEND" />,
    mixpanelContext: 'attend',
    submenus: [
      {
        id: 'conferences',
        label: <FormattedMessage defaultMessage="Conferences" />,
        description: (
          <FormattedMessage defaultMessage="Take part in our events: TED, TEDGlobal and more" />
        ),
        link: '/attend/conferences',
        mixpanelContext: 'attend_conferences',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'tedx-events',
        label: <FormattedMessage defaultMessage="TEDx Events" />,
        description: (
          <FormattedMessage defaultMessage="Find and attend local, independently organized events" />
        ),
        link: 'https://ted.com/tedx/events',
        mixpanelContext: 'attend_tedx',
        pageType: PAGE_TYPES.PAGE_BUILDER
      },
      {
        id: 'ted-on-screen',
        label: <FormattedMessage defaultMessage="TED on Screen" />,
        description: (
          <FormattedMessage defaultMessage="Experience TED from home" />
        ),
        link: '/attend/ted-on-screen',
        mixpanelContext: 'attend_onscreen',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'ted-courses',
        label: <FormattedMessage defaultMessage="TED Courses" />,
        description: (
          <FormattedMessage defaultMessage="Learn from TED speakers who expand on their world-changing ideas" />
        ),
        link: 'https://courses.ted.com/',
        mixpanelContext: 'attend_courses',
        pageType: PAGE_TYPES.EXTERNAL
      }
    ]
  },
  {
    id: 'participate',
    label: <FormattedMessage defaultMessage="PARTICIPATE" />,
    mixpanelContext: 'participate',
    submenus: [
      {
        id: 'nominate',
        label: <FormattedMessage defaultMessage="Nominate" />,
        description: (
          <FormattedMessage defaultMessage="Recommend speakers, TED Prize recipients, Fellows and more" />
        ),
        link: '/participate/nominate',
        mixpanelContext: 'participate_nominate',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'organize',
        label: (
          <FormattedMessage defaultMessage="Organize a local TEDx Event" />
        ),
        description: (
          <FormattedMessage defaultMessage="Rules and resources to help you plan a local TEDx event" />
        ),
        link: '/participate/organize-a-local-tedx-event',
        mixpanelContext: 'participate_organize',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'aboutTedEd',
        label: <FormattedMessage defaultMessage="TED-Ed" />,
        description: (
          <FormattedMessage defaultMessage="Award-winning educational content and programs" />
        ),
        link: '/about/programs/ted-ed',
        mixpanelContext: 'about_programs_ted-ed',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'translate',
        label: <FormattedMessage defaultMessage="Translate" />,
        description: (
          <FormattedMessage defaultMessage="Bring TED to the non-English speaking world" />
        ),
        link: '/participate/translate',
        mixpanelContext: 'participate_translate',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'ted-fellows',
        label: <FormattedMessage defaultMessage="TED Fellows" />,
        description: (
          <FormattedMessage defaultMessage="Join or support innovators from around the globe" />
        ),
        link: 'https://fellows.ted.com/',
        mixpanelContext: 'participate_fellows',
        pageType: PAGE_TYPES.EXTERNAL
      }
    ]
  },
  {
    id: 'about',
    label: <FormattedMessage defaultMessage="ABOUT" />,
    mixpanelContext: 'about',
    submenus: [
      {
        id: 'our-organization',
        label: <FormattedMessage defaultMessage="Our Organization" />,
        description: (
          <FormattedMessage defaultMessage="Our mission, history, team, and more" />
        ),
        link: '/about',
        mixpanelContext: 'about-ted_click',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'conferences',
        label: <FormattedMessage defaultMessage="Conferences" />,
        description: (
          <FormattedMessage defaultMessage="TED Conferences, past, present, and future" />
        ),
        link: '/about/conferences',
        mixpanelContext: 'about_conferences',
        pageType: PAGE_TYPES.PAGE_BUILDER
      },
      {
        id: 'programs-initiatives',
        label: <FormattedMessage defaultMessage="Programs & Initiatives" />,
        description: (
          <FormattedMessage defaultMessage="Details about TED's world-changing initiatives" />
        ),
        link: '/about/programs-initiatives',
        mixpanelContext: 'about_programs',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'partner-with-ted',
        label: <FormattedMessage defaultMessage="Partner with TED" />,
        description: (
          <FormattedMessage defaultMessage="Learn how you can partner with us" />
        ),
        link: '/about/partner-with-ted',
        mixpanelContext: 'about_partner',
        pageType: PAGE_TYPES.PRISMIC
      },
      {
        id: 'ted-blog',
        label: <FormattedMessage defaultMessage="TED Blog" />,
        description: (
          <FormattedMessage defaultMessage="Updates from TED and highlights from our global community" />
        ),
        link: 'https://blog.ted.com/',
        mixpanelContext: 'about_blog',
        pageType: PAGE_TYPES.EXTERNAL
      }
    ]
  }
];

export default topNavigationMenus;
