import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from './index';

export const membershipLink = {
  id: 'membership',
  label: <FormattedMessage defaultMessage="Membership" />,
  fundRaiseUpLabel: <FormattedMessage defaultMessage="Membership" />,
  fundRaiseUpUpperCaseLabel: <FormattedMessage defaultMessage="MEMBERSHIP" />,
  upperCaseLabel: <FormattedMessage defaultMessage="MEMBERSHIP" />,
  link: '/membership?utm_medium=website&utm_source=main-nav-header&utm_campaign=membership-ted',
  // FundraiseUp Link for A/B Test
  fundRaiseUplink: 'https://giving.ted.com/page/fru-member',
  isZenithLink: true,
  mixpanelContext: 'other_membership',
  pageType: PAGE_TYPES.ZENITH,
  fundRaiseUpPageType: PAGE_TYPES.EXTERNAL
};

export default membershipLink;
