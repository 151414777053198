import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from './index';

export const profileLink = {
  id: 'profile',
  label: <FormattedMessage defaultMessage="Your profile" />,
  link: '/profile',
  isZenithLink: true,
  mixpanelContext: 'profile_profile',
  pageType: PAGE_TYPES.EXTERNAL
};

export default profileLink;
