import cx from 'classnames';
import mixpanel from 'lib/analytics/mixpanel';
import { Fragment } from 'react';

import Divider from 'components/divider';
import { Link } from 'components/router';
import SkeletonLoader from 'components/skeleton-loader';
import { Text } from 'components/typography';

import { PAGE_TYPES, profileLink, settingsLink, signOutLink } from '../../data';
import UserImage from '../UserImage';

import UserLinkListProps, {
  LinkList,
  LinkListType
} from './UserLinkList.props';

export default function UserLinkList({
  linkList,
  type,
  isLoading,
  userData
}: UserLinkListProps): React.ReactNode {
  const isInMenu = type === LinkListType.Menu;
  const wrapperClasses = cx({
    'w-60': isInMenu
  });

  // This is done to handle Prismic Routing as well as Page Builder
  // If it isn't a Zenith link its converted to an A Tag rather than a Link tag
  const isZenithLink = (linkType: string | boolean) =>
    linkType == PAGE_TYPES.ZENITH || linkType == PAGE_TYPES.PRISMIC;

  const handleSignoutTracking = () => {
    // Tracking Global Link
    mixpanel.track('global_nav_click', {
      system_language: navigator?.language || null,
      event_interaction_context: signOutLink.mixpanelContext
    });
    // Overwriting Registered Super property for signed in, since user is now signed out
    mixpanel.register({
      signed_in: false
    });
  };

  const renderLinks = () => {
    return (
      <>
        <Divider className="my-8" />

        <div>
          {linkList.map(link => (
            <Fragment key={link.link}>
              {isZenithLink(link.pageType as string) ? (
                <LinkItem link={link}>
                  <Text
                    size="m"
                    className="font-bold group-hover:underline dark:text-white"
                  >
                    {link.label}
                  </Text>

                  {link.badge}
                </LinkItem>
              ) : (
                <AItem link={link}>
                  <Text
                    size="m"
                    className="font-bold group-hover:underline dark:text-white"
                  >
                    {link.label}
                  </Text>

                  {link.badge}
                </AItem>
              )}
            </Fragment>
          ))}
        </div>

        <Divider className="my-8" />

        <a
          className="userLink mb-4 block hover:underline md:mb-5"
          href={settingsLink.link}
          onClick={() =>
            mixpanel.track('global_nav_click', {
              system_language: navigator?.language || null,
              event_interaction_context: settingsLink.mixpanelContext
            })
          }
        >
          <Text size="m" className="font-bold dark:text-white">
            {settingsLink.label}
          </Text>
        </a>

        <a
          className="userLink block hover:underline"
          href={signOutLink.link}
          onClick={() =>
            mixpanel.track('global_nav_click', {
              system_language: navigator?.language || null,
              event_interaction_context: signOutLink.mixpanelContext
            })
          }
        >
          <Text
            size="m"
            className="font-bold text-gray-700 dark:text-[#CFCFD0]"
          >
            {signOutLink.label}
          </Text>
        </a>
      </>
    );
  };

  const renderUserInfo = () => {
    const userInfoWrapperClasses = cx({
      'mt-8 flex justify-between': !isInMenu
    });

    const userNameClasses = cx('mb-1 font-bold dark:text-white', {
      'mr-14': !isInMenu
    });
    return (
      <div className={userInfoWrapperClasses}>
        <div>
          <Text size="m" className={userNameClasses}>
            {isLoading ? (
              <SkeletonLoader.Content numberOfLines={1} className="w-48" />
            ) : (
              userData?.name?.full
            )}
          </Text>
          <a
            className="userLink block hover:underline"
            href={profileLink.link}
            onClick={() =>
              mixpanel.track('global_nav_click', {
                system_language: navigator?.language || null,
                event_interaction_context: profileLink.mixpanelContext
              })
            }
          >
            <Text size="m" className="text-gray-700 dark:text-[#CFCFD0]">
              {profileLink.label}
            </Text>
          </a>
        </div>
        {!isInMenu && (
          <UserImage
            className="h-8 w-8 shrink-0"
            userImage={userData?.avatar?.url || userData?.avatar?.generatedUrl}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  };

  return (
    <div className={wrapperClasses}>
      {renderUserInfo()}
      {renderLinks()}
    </div>
  );
}

type LinkItemProps = {
  link: LinkList;
  children: React.ReactNode;
};

const LinkItem = (props: LinkItemProps) => {
  const { link, children } = props;
  return (
    <Link
      className={cx('userLink group mb-4 last:mb-0 md:mb-5', {
        block: !link.badge,
        'flex items-center gap-2': link.badge
      })}
      href={link.link}
      isZenithLink={link.isZenithLink}
      onClick={() =>
        mixpanel.track('global_nav_click', {
          system_language: navigator?.language || null,
          event_interaction_context: link.mixpanelContext
        })
      }
    >
      {children}
    </Link>
  );
};

const AItem = (props: LinkItemProps) => {
  const { link, children } = props;
  return (
    <a
      className={cx('userLink group mb-4 last:mb-0 md:mb-5', {
        block: !link.badge,
        'flex items-center gap-2': link.badge
      })}
      href={link.link}
      onClick={() =>
        mixpanel.track('global_nav_click', {
          system_language: navigator?.language || null,
          event_interaction_context: link.mixpanelContext
        })
      }
    >
      {children}
    </a>
  );
};
