import cx from 'classnames';

import { Ratio } from 'components/@tedui';
import { ImageProps } from 'components/skeleton-loader';

const SkeletonImage = ({ ratio = [16, 9], className }: ImageProps) => {
  return (
    <div
      data-testid="skeleton-image"
      className={cx(className, `animate-pulse`)}
    >
      <Ratio ratio={ratio} className="bg-gray-300" />
    </div>
  );
};

export default SkeletonImage;
