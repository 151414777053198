import * as React from 'react';
import FooterBottom from './footer-bottom';
import FooterTop from './footer-top';

export type NavItem = {
  label: string;
  url?: string;
};

export type FooterTopNavItem = {
  label: string;
  url?: string;
  items?: NavItem[];
  text?: string;
  mixpanelContext?: string;
};

export type FooterPromoItems = {
  membership: FooterTopNavItem;
  membershipDashboard: FooterTopNavItem;
};

const Footer = (): React.ReactNode => {
  return (
    <footer className="w-full bg-black">
      <FooterTop />
      <FooterBottom />
    </footer>
  );
};

export default Footer;
