import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from './index';

export const settingsLink = {
  id: 'account-settings',
  label: <FormattedMessage defaultMessage="Account settings" />,
  link: '/settings/account',
  isZenithLink: true,
  mixpanelContext: 'profile_account',
  pageType: PAGE_TYPES.EXTERNAL
};

export default settingsLink;
