import cx from 'classnames';
import * as React from 'react';

import type { JSX } from 'react';

type GridProps = {
  tagName?: keyof JSX.IntrinsicElements;
  eightColGrid?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const Grid = ({
  tagName,
  children,
  eightColGrid,
  className,
  ...props
}: GridProps) => {
  const gridClasses = cx(className, 'ted-grid -mr-4 flex flex-wrap md:-mr-5', {
    'ted-grid-8-col': eightColGrid
  });
  const defaultTagName = 'div';
  const Tag = tagName || defaultTagName;

  return (
    <Tag className={gridClasses} {...props}>
      {children}
    </Tag>
  );
};

export default Grid;
