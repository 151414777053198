import type { JSX } from 'react';

type TedLogoIconProps = {
  className?: string;
  variant?: 'red' | 'white';
  ariaHidden?: boolean;
};

const TedLogoIcon = (props: TedLogoIconProps): JSX.Element => {
  const { className, variant, ariaHidden } = props;
  switch (variant) {
    case 'white':
      return (
        <svg
          className={className || ''}
          aria-hidden={ariaHidden}
          width="67"
          height="24"
          viewBox="0 0 67 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1694_77589)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.9659 0V6.15129H14.2028V24H6.76305V6.15129H0V0H20.9659ZM42.4495 0V6.14144L29.5319 6.15129V9.09722H42.4495V14.8271L29.5319 14.8182V17.8489H42.4495V24H22.0923V0H42.4495ZM55.896 0C63.9438 0 66.7844 5.91584 66.7844 11.9665C66.7844 19.3279 62.8623 24 54.4415 24H43.6885V0H55.896ZM53.495 6.15129H51.1281V17.8489H54.0358C58.6689 17.8489 59.3451 14.1177 59.3451 11.8658C59.3451 10.3532 58.8716 6.15129 53.495 6.15129Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1694_77589">
              <rect width="67" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'red':
    default:
      return (
        <svg
          className={className || ''}
          aria-hidden={ariaHidden}
          viewBox="0 0 68 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.419 0V6.15129H14.6559V24H7.21617V6.15129H0.453125V0H21.419ZM42.9026 0V6.14144L29.985 6.15129V9.09722H42.9026V14.8271L29.985 14.8182V17.8489H42.9026V24H22.5455V0H42.9026ZM56.3491 0C64.3969 0 67.2375 5.91584 67.2375 11.9665C67.2375 19.3279 63.3154 24 54.8947 24H44.1417V0H56.3491ZM53.9481 6.15129H51.5812V17.8489H54.489C59.122 17.8489 59.7983 14.1177 59.7983 11.8658C59.7983 10.3532 59.3247 6.15129 53.9481 6.15129Z"
            fill="#EB0028"
          />
        </svg>
      );
  }
};
export default TedLogoIcon;
