import { Text } from 'components/@tedui';
import Col from 'components/grid/col';
import { Link } from 'components/router';
import { useLocalStorage } from 'lib/hooks/useLocalStorage';

import { TextProps } from 'components/@tedui/Text';
import mixpanel from 'lib/analytics/mixpanel';
import { getSystemLanguage } from 'lib/analytics/mixpanel-helpers';
import type React from 'react';
import { PAGE_TYPES } from '../../Navigation/data';

export type FooterColumnListItemProps = Omit<
  TextProps,
  'children' | 'isBold'
> & {
  label: string;
  sublabel: string;
  url: string;
  mixpanelContext: string;
  pageType: string;
};

const FooterColumnListItem = ({
  label,
  sublabel,
  url,
  tag = 'span',
  variant,
  UNSAFE_className,
  mixpanelContext,
  testId = 'footer-link',
  pageType
}: FooterColumnListItemProps) => {
  // This is done to handle Prismic Routing as well as Page Builder
  // If it isn't a Zenith link its converted to an A Tag rather than a Link tag
  const isZenithLink = linkType =>
    linkType == PAGE_TYPES.ZENITH || linkType == PAGE_TYPES.PRISMIC;
  const [, setNavigationContext] = useLocalStorage('navigation_context', null);
  const clickHandler = () => {
    // Set Navigation Context
    setNavigationContext(`Footer:${label}:${sublabel}`);
    // MixPanel Analytics Tracking
    mixpanel.track('global_footer_click', {
      event_interaction_context: mixpanelContext,
      system_language: getSystemLanguage(),
      ...(url.includes('https://') && {
        url
      })
    });
    // Window Location
  };
  return (
    <Col sm={2} md={12} lg={8} xl={12} tagName="li" className="mb-5 max-w-max">
      {isZenithLink(pageType) ? (
        <LinkItem url={url} clickHandler={clickHandler}>
          <Text
            UNSAFE_className={UNSAFE_className}
            variant={variant}
            tag={tag}
            testId={testId}
            color={{
              isOnLightSurface: false
            }}
          >
            {sublabel}
          </Text>
        </LinkItem>
      ) : (
        <AItem url={url} clickHandler={clickHandler}>
          <Text
            UNSAFE_className={UNSAFE_className}
            variant={variant}
            tag={tag}
            testId={testId}
            color={{
              isOnLightSurface: false
            }}
          >
            {sublabel}
          </Text>
        </AItem>
      )}
    </Col>
  );
};

type LinkItemsProps = {
  url: string;
  clickHandler: () => void;
  children: React.ReactNode;
};

const LinkItem = ({ url, clickHandler, children }: LinkItemsProps) => {
  return (
    <Link
      className="text-white hover:underline"
      href={url}
      key={url}
      onClick={clickHandler}
    >
      {children}
    </Link>
  );
};

const AItem = (props: LinkItemsProps) => {
  const { url, clickHandler, children } = props;
  return (
    <a
      className="text-white hover:underline"
      href={url}
      key={url}
      onClick={clickHandler}
    >
      {children}
    </a>
  );
};

export default FooterColumnListItem;
