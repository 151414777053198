import { removeLineBreaksAndMultiSpaces } from 'lib/util';
import Script from 'next/script';

export const FundraiseUp = () => {
  return (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script id="fundraiseUpCode" strategy="beforeInteractive">
      {removeLineBreaksAndMultiSpaces(`
        (function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
      .split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
      :function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
      j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
      t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
      for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
    })(window,document,'script','FundraiseUp','AVFWZGUV');
        `)}
    </Script>
  );
};

type FundraiseUpRevenueTrackingProps = {
  revenue?: number;
};

export const FundraiseUpRevenueTracking = ({
  revenue
}: FundraiseUpRevenueTrackingProps) => {
  return revenue ? (
    <Script id="fundraiseUpRevTrackCode" strategy="afterInteractive">
      {removeLineBreaksAndMultiSpaces(
        `window._vis_opt_queue = window._vis_opt_queue || [];window._vis_opt_queue.push(function() {_vis_opt_revenue_conversion("${revenue}");});`
      )}
    </Script>
  ) : (
    <></>
  );
};

export default FundraiseUp;
