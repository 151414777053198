import type { JSX } from 'react';
import FacebookIcon from 'icons/FacebookIcon';
import InstagramIcon from 'icons/InstagramIcon';
import LinkedInIcon from 'icons/LinkedinIcon';
import TwitterIcon from 'icons/TwitterIcon';
import YoutubeIcon from 'icons/YoutubeIcon';

type SocialMediaIconsProps = {
  className?: string;
  variant: 'linkedin' | 'twitter' | 'instagram' | 'youtube' | 'facebook';
  label?: string;
};

const SocialMediaIcons = (props: SocialMediaIconsProps): JSX.Element => {
  const { className, variant, label } = props;
  switch (variant) {
    case 'facebook':
      return (
        <FacebookIcon
          label={label}
          variant="circle-white"
          className={className}
        />
      );
    case 'instagram':
      return (
        <InstagramIcon
          label={label}
          variant="circle-white"
          className={className}
        />
      );
    case 'linkedin':
      return (
        <LinkedInIcon
          label={label}
          variant="circle-white"
          className={className}
        />
      );
    case 'twitter':
      return (
        <TwitterIcon
          label={label}
          variant="circle-white"
          className={className}
        />
      );
    case 'youtube':
      return (
        <YoutubeIcon
          label={label}
          variant={'circle-white'}
          className={className}
        />
      );
    default:
      return <></>;
  }
};
export default SocialMediaIcons;
