import cx from 'classnames';
type ContentContainerProps = {
  className?: string;
  children?: React.ReactNode;
  noPadding?: boolean;
  style?: React.CSSProperties;
  addMaxWidth?: boolean;
  customMaxWidth?: '2xl' | '2.5xl' | '3xl';
};

export default function ContentContainer({
  className = '',
  style,
  children,
  noPadding,
  addMaxWidth = true,
  customMaxWidth,
  ...props
}: ContentContainerProps): React.ReactNode {
  const widthVariants = {
    '2xl': 'max-w-screen-2xl',
    '2.5xl': 'max-w-screen-2.5xl',
    '3xl': 'max-w-screen-3xl'
  };
  const maxWidth = customMaxWidth
    ? `${widthVariants[customMaxWidth]}`
    : {
        'max-w-[1200px]': addMaxWidth
      };
  return (
    <div
      className={cx('w-full', className, {
        'px-5 md:px-10 xl:px-16': !noPadding
      })}
      style={style}
      {...props}
    >
      <div className={cx('mx-auto w-full sm:px-0', maxWidth)}>{children}</div>
    </div>
  );
}
