import create from 'zustand';

export const [useLayoutStore] = create(set => ({
  showMenuLinks: true,
  showLinksFooter: true,

  setShowMenuLinks: (value: boolean) => {
    set({ showMenuLinks: value });
  },

  setShowLinksFooter: (value: boolean) => {
    set({ showLinksFooter: value });
  }
}));
