import { FormattedMessage } from 'react-intl';

import mixpanel from 'lib/analytics/mixpanel';
import { getSystemLanguage } from 'lib/analytics/mixpanel-helpers';

import ContentContainer from 'components/content-container';
import Divider from 'components/divider';
import Grid from 'components/grid';
import Col from 'components/grid/col';
import { Link } from 'components/router';
import { Text } from 'components/typography';
import { PAGE_TYPES } from '../Navigation/data';

const navItems = [
  {
    label: 'TED Talks Usage Policy',
    url: '/about/our-organization/our-policies-terms/ted-talks-usage-policy',
    mixpanelContext: 'about_ted-talks-usage-policy',
    pageType: PAGE_TYPES.PRISMIC
  },
  {
    label: 'Privacy Policy',
    url: '/about/our-organization/our-policies-terms/privacy-policy',
    mixpanelContext: 'about_privacy-policy',
    pageType: PAGE_TYPES.PRISMIC
  },
  {
    label: 'Advertising / Partnership',
    url: '/about/partner-with-ted',
    mixpanelContext: 'about_advertising-partnership',
    pageType: PAGE_TYPES.PRISMIC
  },
  {
    label: 'TED.com Terms of Use',
    url: '/about/our-organization/our-policies-terms/ted-com-terms-of-use',
    mixpanelContext: 'about_ted-terms-of-use',
    pageType: PAGE_TYPES.PRISMIC
  },
  {
    label: 'Jobs',
    url: '/about/our-organization/jobs-at-ted',
    mixpanelContext: 'about_jobs-at-ted',
    pageType: PAGE_TYPES.PRISMIC
  },
  {
    label: 'Press',
    url: '/about/our-organization/contact-us/press-and-media-information',
    mixpanelContext: 'about_press',
    pageType: PAGE_TYPES.PRISMIC
  },
  {
    label: 'Help',
    url: 'https://support.ted.com',
    mixpanelContext: 'about_help',
    pageType: PAGE_TYPES.EXTERNAL
  }
];

const FooterBottom = () => {
  return (
    <div className="w-full pr-5">
      <ContentContainer addMaxWidth={false}>
        <Grid className="border-t-thin border-textTertiary-onDark">
          <Col sm={12} xl={8}>
            <nav>
              <Grid
                className="pb-3 pt-8 md:pt-10 lg:pb-5 lg:pt-10"
                tagName="ul"
              >
                {navItems.map(({ label, url, mixpanelContext, pageType }) => (
                  <Col
                    sm={2}
                    md={3}
                    lg={3}
                    xl={3}
                    key={label}
                    tagName="li"
                    className="mb-5 max-w-max"
                  >
                    {pageType === PAGE_TYPES.ZENITH ||
                    pageType === PAGE_TYPES.PRISMIC ? (
                      <LinkItem
                        label={label}
                        url={url}
                        mixpanelContext={mixpanelContext}
                      />
                    ) : (
                      <AItem
                        label={label}
                        url={url}
                        mixpanelContext={mixpanelContext}
                      />
                    )}
                  </Col>
                ))}

                <Col
                  sm={2}
                  md={3}
                  lg={3}
                  xl={3}
                  tagName="li"
                  className="mb-5 max-w-max"
                >
                  <button
                    type="button"
                    className="text-textTertiary-onDark hover:underline"
                    onClick={() => window.OneTrust?.ToggleInfoDisplay()}
                  >
                    <Text size="m" tagName="span">
                      Privacy Preferences
                    </Text>
                  </button>
                </Col>
              </Grid>
            </nav>
          </Col>
          <Col sm={0} forceSmValue customSmMdPadding="pr-0 md:pr-0" xl={1} />
          <Col sm={12} lg={4} xl={3}>
            <Divider className="xl:hidden" />
            <Text
              size="m"
              className="pb-12 text-textTertiary-onDark xl:pl-3 xl:pt-10"
            >
              <FormattedMessage defaultMessage="© TED Conferences, LLC. All rights reserved." />
            </Text>
          </Col>
        </Grid>
      </ContentContainer>
    </div>
  );
};

// The whole reason for creating these two separate components is because Prismic Routing and Zenith Links can work internally via Link Components
// while Outbound links to RoadRunner/Page Builder need an A Tag and the Link Component doesn't work because it checks internal Zenith Links
type LinkItemsProps = {
  url: string;
  label: string;
  mixpanelContext: string;
};

// Handles links that are internal to Zenith (including Prismic)
const LinkItem = (props: LinkItemsProps) => {
  const { url, label, mixpanelContext } = props;
  return (
    <Link
      href={url}
      className="text-textTertiary-onDark hover:underline"
      onClick={() => {
        mixpanel.track('global_footer_click', {
          event_interaction_context: mixpanelContext,
          system_language: getSystemLanguage(),
          ...(url.includes('https://') && {
            url
          })
        });
      }}
    >
      <Text size="m" tagName="span">
        {label}
      </Text>
    </Link>
  );
};

// Handles links that are external to Zenith (including PageBuilder/RoadRunner)
// The Link Component doesn't work because it checks internal Zenith Links with its isInternalLink function
const AItem = (props: LinkItemsProps) => {
  const { url, label, mixpanelContext } = props;
  return (
    <a
      href={url}
      className="text-textTertiary-onDark hover:underline"
      onClick={() => {
        mixpanel.track('global_footer_click', {
          event_interaction_context: mixpanelContext,
          system_language: getSystemLanguage(),
          ...(url.includes('https://') && {
            url
          })
        });
      }}
    >
      <Text size="m" tagName="span">
        {label}
      </Text>
    </a>
  );
};

export default FooterBottom;
