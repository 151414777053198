import type { JSX } from 'react';

type TwitterIconProps = {
  className?: string;
  variant: 'circle-white';
  label?: string;
};

const TwitterIcon = (props: TwitterIconProps): JSX.Element => {
  const { className, variant, label } = props;
  switch (variant) {
    case 'circle-white':
      return (
        <svg
          className={className || ''}
          aria-label={label}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1694_78302)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16.8877 13.292L16.8541 12.7383C16.7534 11.3034 17.6376 9.99275 19.0365 9.48431C19.5513 9.30353 20.4243 9.28093 20.9951 9.43911C21.2189 9.50691 21.6442 9.73288 21.9463 9.93626L22.4947 10.3091L23.0991 10.117C23.4348 10.0153 23.8825 9.84587 24.084 9.73288C24.2742 9.63119 24.4421 9.5747 24.4421 9.60859C24.4421 9.80067 24.028 10.456 23.6811 10.8176C23.211 11.326 23.3453 11.3712 24.2966 11.0322C24.8674 10.8402 24.8786 10.8402 24.7666 11.0548C24.6995 11.1678 24.3526 11.5633 23.9832 11.9248C23.3565 12.5463 23.3229 12.614 23.3229 13.1338C23.3229 13.936 22.9424 15.6082 22.5619 16.5234C21.8568 18.2408 20.3459 20.0147 18.8351 20.9073C16.7086 22.1614 13.8772 22.4778 11.4933 21.7434C10.6987 21.4948 9.33333 20.8621 9.33333 20.7491C9.33333 20.7152 9.74743 20.67 10.251 20.6587C11.3031 20.6361 12.3551 20.3424 13.2504 19.8226L13.8548 19.4611L13.1609 19.2238C12.176 18.8848 11.2919 18.1052 11.068 17.3708C11.0009 17.1335 11.0233 17.1222 11.65 17.1222L12.2991 17.1109L11.7507 16.8511C11.1016 16.5234 10.5085 15.9698 10.2175 15.4048C10.0048 14.9981 9.73623 13.9699 9.81458 13.8908C9.83696 13.8569 10.072 13.9247 10.3406 14.0151C11.1128 14.2976 11.2135 14.2298 10.7659 13.7552C9.92649 12.8965 9.66908 11.6198 10.072 10.4108L10.2622 9.86846L11.0009 10.6029C12.5118 12.083 14.2912 12.9643 16.3281 13.2242L16.8877 13.292Z"
              fill="#F6F6F6"
            />
          </g>
          <defs>
            <clipPath id="clip0_1694_78302">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <></>;
  }
};
export default TwitterIcon;
