import type { JSX } from 'react';
import AppleStoreIcon from 'icons/AppleStoreIcon';
import GooglePlayIcon from 'icons/GooglePlayIcon';

type AppStoreIconProps = {
  className?: string;
  ariaLabel?: string;
  variant: 'Apple' | 'Google';
};

const AppStoreIcon = (props: AppStoreIconProps): JSX.Element => {
  const { className, ariaLabel, variant } = props;
  if (variant === 'Apple') {
    return <AppleStoreIcon ariaLabel={ariaLabel} className={className} />;
  }
  if (variant === 'Google') {
    return <GooglePlayIcon ariaLabel={ariaLabel} className={className} />;
  }
  return <></>;
};
export default AppStoreIcon;
