import { useEffect, useState } from 'react';
import {
  MASTER_LIST,
  RIBBON_SUBSCRIPTION_MAP,
  RIBBON_SUBSCRIPTION_SOURCE_MAP,
  TED_ED_DAILY_VARS,
  TED_ED_WEEKLY_VARS,
  TTD_VARS,
  TTR_VARS,
  TED_TECH_BIWEEKLY_VARS
} from './const';

type UseSailthruInput = {
  signUp: {
    email: string;
    newsletterName: string;
    frequency?: string;
    onSuccess: () => void;
    onError: () => void;
  };
  getContent: {
    onSuccess: (content: unknown) => void;
    onError: (error: unknown) => void;
    vars?: { [key: string]: string | boolean | number };
    sections?: {
      id: string;
      selector?: string;
      onSuccess?: (content: unknown) => void;
      onError?: (error: unknown) => void;
    }[];
  };
  multiNewsletterSignup: {
    email: string;
    newsletterNames: string[];
    source?: string;
    onSuccess: () => void;
    onError: (error: unknown) => void;
  };
};

export function useSailthru(): {
  signUp: ({
    email,
    newsletterName,
    onSuccess,
    onError
  }: UseSailthruInput['signUp']) => void;
  getContent: ({
    onSuccess,
    onError,
    vars,
    sections
  }: UseSailthruInput['getContent']) => void;
  multiNewsletterSignup: ({
    email,
    newsletterNames,
    source,
    onSuccess,
    onError
  }: UseSailthruInput['multiNewsletterSignup']) => void;
} {
  const [sailthruLoaded, setSailthruLoaded] = useState(false);

  useEffect(() => {
    if (window.Sailthru != null) {
      setSailthruLoaded(true);
    }
  }, [sailthruLoaded]);

  const signUp = ({
    email,
    newsletterName,
    frequency,
    onSuccess,
    onError
  }: UseSailthruInput['signUp']) => {
    if (!sailthruLoaded) return;
    let vars: { [key: string]: string | boolean | number };

    switch (newsletterName) {
      case 'TED Recommends':
        vars = TTR_VARS;
        break;
      case 'TED Talks Daily':
        vars = TTD_VARS;
        break;
      case 'TED Ed':
      case 'TED-Ed':
        vars = frequency === 'Weekly' ? TED_ED_WEEKLY_VARS : TED_ED_DAILY_VARS;
        break;
      case 'TED Tech':
        vars = TED_TECH_BIWEEKLY_VARS;
        break;
      default:
        vars = TED_ED_DAILY_VARS;
    }

    window.Sailthru.integration('userSignUp', {
      email,
      lists: MASTER_LIST,
      vars,
      source: 'web',
      onSuccess,
      onError
    });
  };

  const multiNewsletterSignup = ({
    email,
    newsletterNames,
    source = '',
    onSuccess,
    onError
  }: UseSailthruInput['multiNewsletterSignup']) => {
    if (!sailthruLoaded) return;
    let vars: { [key: string]: string | boolean | number } = {};

    type NewsletterName = keyof typeof RIBBON_SUBSCRIPTION_MAP;

    for (const newsletterName of newsletterNames as NewsletterName[]) {
      if (RIBBON_SUBSCRIPTION_MAP[newsletterName]) {
        vars = {
          ...vars,
          ...RIBBON_SUBSCRIPTION_MAP[newsletterName],
          ...RIBBON_SUBSCRIPTION_SOURCE_MAP(newsletterName, source)
        };
      }
    }

    window.Sailthru.integration('userSignUp', {
      email,
      lists: MASTER_LIST,
      vars,
      source,
      onSuccess,
      onError
    });
  };

  const getContent = ({
    onSuccess,
    onError,
    vars,
    sections
  }: UseSailthruInput['getContent']) => {
    if (!sailthruLoaded) return;
    window.Sailthru.personalize({
      sections: sections?.map(section => section.id) as string[],
      vars: vars as { [key: string]: string | boolean },
      onSuccess: () => {
        if (onSuccess) onSuccess({});
      },
      onError: () => {
        if (onError) onError({});
      }
    });
  };

  return { signUp, getContent, multiNewsletterSignup };
}
