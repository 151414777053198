import { ReactNode } from 'react';
import mixpanel from 'lib/analytics/mixpanel';
import { getSystemLanguage } from 'lib/analytics/mixpanel-helpers';
import SocialMediaIcons from 'icons/SocialMediaIcons';
import { Link } from 'components/router';

type FooterSocialLinkProps = {
  Icon: 'linkedin' | 'twitter' | 'instagram' | 'youtube' | 'facebook';
  label: string;
  url: string;
  mixpanelContext: string;
};

export function FooterSocialLink({
  Icon,
  label,
  url,
  mixpanelContext
}: FooterSocialLinkProps): ReactNode {
  return (
    <Link
      href={url}
      onClick={() => {
        mixpanel.track('global_footer_click', {
          event_interaction_context: mixpanelContext,
          system_language: getSystemLanguage(),
          url
        });
      }}
      key={url}
    >
      <SocialMediaIcons variant={Icon} label={label} className="h-8" />
    </Link>
  );
}
