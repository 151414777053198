import cx from 'classnames';
import BaseButton, { BaseButtonProps } from 'components/base-button';
import { Link } from 'components/router';

type LinkButtonProps = {
  children?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  href: string;
  target?: string;
  rel?: string;
  ref?: React.RefObject<HTMLAnchorElement | null>;
  customColors?: {
    background?: string;
    text?: string;
  };
};

const LinkButton = ({
  ref,
  children,
  className = '',
  fullWidth,
  href,
  icon,
  iconPosition,
  small,
  text,
  variant,
  customColors = {},
  ...props
}: LinkButtonProps &
  BaseButtonProps &
  React.HTMLAttributes<HTMLAnchorElement>): React.ReactNode => {
  const buttonClasses = cx(className, 'rounded-sm', {
    'w-full': fullWidth
  });

  return (
    <Link className={buttonClasses} href={href} ref={ref} {...props}>
      <BaseButton
        interactive
        text={text}
        small={small}
        variant={variant}
        icon={icon}
        iconPosition={iconPosition}
        fullWidth={fullWidth}
        style={{
          background: customColors.background,
          color: customColors.text
        }}
      >
        {children}
      </BaseButton>
    </Link>
  );
};

export default LinkButton;
