import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from './index';

const signInLink = {
  id: 'sign-in',
  label: <FormattedMessage defaultMessage="SIGN IN" />,
  link: 'https://auth.ted.com/users/new',
  isZenithLink: false,
  mixpanelContext: 'profile_sign-in',
  pageType: PAGE_TYPES.EXTERNAL
};

export default signInLink;
