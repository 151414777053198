import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from '.';

export const signOutLink = {
  id: 'sign-out',
  label: <FormattedMessage defaultMessage="Sign out" />,
  link: 'https://auth.ted.com/session/logout?referer=https://www.ted.com/session/logout',
  mixpanelContext: 'profile_sign-out',
  isZenithLink: false,
  pageType: PAGE_TYPES.EXTERNAL
};

export default signOutLink;
