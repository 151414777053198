import type { JSX } from 'react';

type YouTubeIconProps = {
  className?: string;
  variant: 'circle-white';
  label?: string;
};

const YouTubeIcon = (props: YouTubeIconProps): JSX.Element => {
  const { className, variant, label } = props;
  if (variant === 'circle-white') {
    return (
      <svg
        className={className || ''}
        aria-label={label}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1694_78351)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM22.6679 10.4995C23.4022 10.701 23.9805 11.2948 24.1768 12.0488C24.5335 13.4153 24.5335 16.2666 24.5335 16.2666C24.5335 16.2666 24.5335 19.1178 24.1768 20.4845C23.9805 21.2385 23.4022 21.8322 22.6679 22.0338C21.3371 22.4 16.0001 22.4 16.0001 22.4C16.0001 22.4 10.6632 22.4 9.3323 22.0338C8.59795 21.8322 8.01962 21.2385 7.82335 20.4845C7.4668 19.1178 7.4668 16.2666 7.4668 16.2666C7.4668 16.2666 7.4668 13.4153 7.82335 12.0488C8.01962 11.2948 8.59795 10.701 9.3323 10.4995C10.6632 10.1333 16.0001 10.1333 16.0001 10.1333C16.0001 10.1333 21.3371 10.1333 22.6679 10.4995Z"
            fill="#F6F6F6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.3999 19.2V13.8667L18.6666 16.5335L14.3999 19.2Z"
            fill="#F6F6F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_1694_78351">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return <></>;
};
export default YouTubeIcon;
