import cx from 'classnames';
import type {
  ChangeEvent,
  FormEvent,
  HTMLAttributes,
  KeyboardEvent
} from 'react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Icon from 'components/@tedui/Icon';
import mixpanel from 'lib/analytics/mixpanel';

import SearchProps from './Search.props';

export default function NavigationSearch({
  value,
  searchId,
  onChange,
  onSubmit,
  onBlur,
  navSearchFocused,
  ...otherProps
}: SearchProps & HTMLAttributes<HTMLInputElement>): React.ReactNode {
  const [expanded, setExpanded] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const closeButtonRef = useRef(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (expanded && inputRef && inputRef.current !== null) {
      requestAnimationFrame(() => inputRef.current!.focus());
    }
  }, [expanded]);

  useEffect(() => {
    if (navSearchFocused) {
      setExpanded(true);
    }
  }, [navSearchFocused]);

  const intl = useIntl();
  const cancelButtonText = intl.formatMessage({
    defaultMessage: 'Cancel'
  });

  const placeholder = intl.formatMessage({
    defaultMessage: 'Type to search'
  });

  const handleMagnifierKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Escape' &&
      searchButtonRef &&
      searchButtonRef.current !== null
    ) {
      setExpanded(false);
      requestAnimationFrame(() => searchButtonRef.current!.focus());

      if (onBlur) onBlur();
    }
  };

  const handleCancel = () => {
    setExpanded(false);

    if (searchButtonRef && searchButtonRef.current !== null) {
      requestAnimationFrame(() => searchButtonRef.current!.focus());
    }

    if (onChange)
      onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);

    if (onBlur) onBlur();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) onSubmit();
  };

  const handleBlur = () => {
    requestAnimationFrame(() => {
      if (
        document.activeElement === closeButtonRef.current ||
        document.activeElement === inputRef.current
      )
        return;

      setExpanded(false);
      if (onBlur) {
        onBlur();
      }
    });
  };

  const wrapperClasses = cx(
    '-mr-6 box-content flex h-14 shrink-0 flex-row flex-nowrap items-center justify-start overflow-hidden pr-6 transition-all',
    {
      'w-11 bg-white dark:bg-black': !expanded,
      'w-headerSearch bg-gray-50 dark:bg-gray-800': expanded
    }
  );

  const searchButtonClasses = cx(
    'outline-inside flex h-11 shrink-0 translate-x-15 items-center justify-center transition-width hover:text-gray-700 dark:text-white dark:hover:text-gray-350',
    {
      'w-0 opacity-0': expanded,
      'w-11 opacity-100': !expanded
    }
  );

  const cancelButtonClasses = cx(
    'translate-x-15 p-15 transition-opacity hover:text-gray-700',
    {
      'opacity-0': !expanded,
      'opacity-100': expanded
    }
  );

  return (
    <form onSubmit={e => handleSubmit(e)} className={wrapperClasses}>
      <button
        type="button"
        className={searchButtonClasses}
        onClick={() => {
          setExpanded(true);
          mixpanel.track('global_nav_click', {
            system_language: navigator?.language || null,
            event_interaction_context: 'other_search'
          });
        }}
        disabled={expanded}
        aria-label={placeholder}
        ref={searchButtonRef}
      >
        <Icon iconName="search" className="text-tui-4xl" />
      </button>
      <div className="flex size-full min-w-0 items-center">
        <label htmlFor={searchId} className="sr-only">
          {placeholder}
        </label>
        <input
          type="search"
          id={searchId}
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          aria-label={placeholder}
          onChange={onChange}
          onKeyDown={handleMagnifierKeyPress}
          className="h-full min-w-0 flex-1 border-none bg-transparent pl-6 pr-05 text-sm outline-none focus:ring-0 dark:text-gray-350"
          disabled={!expanded}
          onBlur={handleBlur}
          {...otherProps}
        />
        <button
          type="button"
          className={cancelButtonClasses}
          onClick={handleCancel}
          disabled={!expanded}
          aria-label={cancelButtonText}
          ref={closeButtonRef}
          onBlur={handleBlur}
        >
          <Icon iconName="x" className="text-tui-4xl dark:text-gray-350" />
        </button>
      </div>
    </form>
  );
}
