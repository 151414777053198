import { assoc, curry, identity, ifElse, join, prop, values } from 'f';
import hexRgb from 'hex-rgb';

const colors: Record<string, string | string[]> = {
  success: ['#26a65b', '#e0f2e8'],
  warning: ['#e5af0a', '#faf0cf'],
  error: ['#d91e18', '#ffeeef'],
  gray: ['#494949', '#717171', 'rgba(18, 18, 18, .12)', '#F6F6F6'],
  TEDRed: ['#EB0028', 'rgba(236, 16, 21, 0.15)'],
  black: '#121212',
  white: '#FFFFFF',
  blue: '#2763FF'
};

const wrapInRGBA = (v: string | string[]): string => {
  if (Array.isArray(v)) {
    return `rgba(${v.join(',')})`;
  }
  return `rgba(${v})`;
};

export const toRGBA = curry((color: string | string[], alpha) => {
  const selectedColor = ifElse<any, string, string>(
    (c: string) => Boolean(prop(c)(colors)),
    (c: string) => {
      const selected = prop(c)(colors);
      return Array.isArray(selected) ? selected[0] : selected;
    },
    identity
  );
  const colorString = Array.isArray(color) ? color.join(',') : color;
  return wrapInRGBA(
    join(',', values(assoc('alpha', alpha, hexRgb(selectedColor(colorString)))))
  );
});

const scale = Array.from({ length: 500 }, (_v, i) => `${i * 4}px`);

const BASE = {
  colors,
  space: {
    ...scale.reduce(
      (acc, curr, i) => ({ ...acc, [i]: curr, [`n${i}`]: `-${curr}` }),
      {}
    ),
    release: 'calc(-50vw + 50%)',
    root: 0
  },
  breakpoints: [
    '400px',
    '576px',
    '768px',
    '992px',
    '1024px',
    '1200px',
    '1600px'
  ],
  fontWeights: Array.from({ length: 10 }, (_v, i) => i * 100),
  fonts: {
    0: 'Inter, "Helvetica Neue", Arial, sans-serif',
    1: 'Georgia, serif',
    2: 'Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace',
    sans: 'Inter, "Helvetica Neue", Arial, sans-serif',
    serif: 'Georgia, serif',
    mono: 'Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace'
  },
  fontSizes: Array.from({ length: 10 }, (_v, i) => `${i}px`),
  lineHeights: ['0px', '1px', '1.5px', '1px'],
  shadows: { ghost: 'inset 0 0 0 1px currentColor' },
  letterSpacings: ['-0.05em', '-0.025em', '0.015em'],
  radii: { 0: '0px', 1: '2px', round: '50%' },
  sizes: null,
  mediaQueries: {},
  styles: {}
};

BASE.sizes = BASE.space;

BASE.mediaQueries = BASE.breakpoints.reduce(
  (acc, curr, i) => ({
    ...acc,
    [i === 0
      ? 'xs'
      : i === 1
        ? 'sm'
        : i === 2
          ? 'md'
          : i === 3
            ? 'lg'
            : i === 5
              ? 'xl'
              : 'xxl']: `@media screen and (min-width: ${curr})`
  }),
  {}
);

BASE.styles = {
  root: {
    fontFamily: 'sans',
    fontSize: 'root',
    color: 'black',
    backgroundColor: 'white',
    lineHeight: '16px',
    WebkitFontSmoothing: 'subpixel-antialiased'
  }
};

export const tokens = BASE;
