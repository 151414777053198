import cx from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState,
  type ReactNode,
  type RefObject
} from 'react';

type MenuContextType = {
  isExpanded: boolean;
  onSelect?: (value: unknown) => void;
  toggleMenu: () => void;
};

export const MenuContext = React.createContext<MenuContextType>({
  isExpanded: false,
  toggleMenu: () => {}
});

type MenuProps = {
  children: ((props: { isExpanded: boolean }) => ReactNode) | ReactNode;
  onSelect?: (value: unknown) => void;
  type?: 'hover' | 'click';
};

export const Menu = ({ children, onSelect, type = 'hover' }: MenuProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsExpanded(false);
      }
    };

    if (type === 'click') {
      document.addEventListener('mousedown', handleClickOutside);
    }
    document.addEventListener('keydown', handleEscape);

    return () => {
      if (type === 'click') {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      document.removeEventListener('keydown', handleEscape);
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    };
  }, [type]);

  const handleMouseEnter = () => {
    if (type !== 'hover') return;
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    if (type !== 'hover') return;
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setIsExpanded(false);
    }, 100);
  };

  const toggleMenu = () => {
    if (type === 'click') {
      setIsExpanded(prev => !prev);
    }
  };

  const contextValue = {
    isExpanded,
    toggleMenu,
    onSelect: (value: unknown) => {
      onSelect?.(value);
      setIsExpanded(false);
    }
  };

  return (
    <MenuContext.Provider value={contextValue}>
      <div
        ref={menuRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative"
      >
        {typeof children === 'function' ? children({ isExpanded }) : children}
      </div>
    </MenuContext.Provider>
  );
};

type MenuButtonProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  'aria-label'?: string;
  ref?: RefObject<HTMLButtonElement>;
};

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  ({ children, className, onClick, 'aria-label': ariaLabel }, ref) => {
    const { isExpanded, toggleMenu } = React.useContext(MenuContext);
    const id = useId();

    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault();
      toggleMenu();
      onClick?.();
    };

    return (
      <button
        ref={ref}
        id={id}
        className={className}
        onClick={handleClick}
        aria-expanded={isExpanded}
        aria-haspopup="true"
        aria-label={ariaLabel}
      >
        {children}
      </button>
    );
  }
);

MenuButton.displayName = 'MenuButton';

type MenuListProps = {
  children: ReactNode;
  className?: string;
  portal?: boolean;
  position?: 'absolute' | 'fixed';
  style?: React.CSSProperties;
};

export const MenuList = ({
  children,
  className,
  portal = true,
  position = 'absolute',
  style
}: MenuListProps) => {
  const { isExpanded } = React.useContext(MenuContext);

  if (!isExpanded) return null;

  const content = (
    <div
      role="menu"
      className={cx(className, position)}
      style={{
        ...style,
        position,
        zIndex: 50
      }}
    >
      {children}
    </div>
  );

  if (portal) {
    // You can implement portal logic here if needed
    return content;
  }

  return content;
};

type MenuItemProps = {
  children: ReactNode;
  className?: string;
  onSelect?: () => void;
};

export const MenuItem = ({ children, className, onSelect }: MenuItemProps) => {
  const { onSelect: contextOnSelect } = React.useContext(MenuContext);

  return (
    <div
      role="menuitem"
      className={cx('outline-none', className)}
      onClick={() => {
        onSelect?.();
        contextOnSelect?.(true);
      }}
      tabIndex={0}
    >
      {children}
    </div>
  );
};

type MenuPopoverProps = {
  children: ReactNode;
  className?: string;
  position?: 'absolute' | 'fixed';
  style?: React.CSSProperties;
};

export const MenuPopover = ({
  children,
  className,
  position = 'absolute',
  style
}: MenuPopoverProps) => {
  const { isExpanded } = React.useContext(MenuContext);

  if (!isExpanded) return null;

  return (
    <div
      role="menu"
      className={cx(className, position)}
      style={{
        ...style,
        position,
        zIndex: 50
      }}
    >
      {children}
    </div>
  );
};

type MenuItemsProps = {
  children: ReactNode;
  className?: string;
};

export const MenuItems = ({ children, className }: MenuItemsProps) => {
  return (
    <div role="menu" className={className}>
      {children}
    </div>
  );
};
