import { Text } from 'components/typography';
import mixpanel from 'lib/analytics/mixpanel';
import { getSystemLanguage } from 'lib/analytics/mixpanel-helpers';

import { Link } from 'components/router';

import AppStoreIcon from 'icons/AppStoreIcons';

const footerAppStoreLinks = [
  {
    label: 'Google Play',
    Icon: 'Google',
    url: 'https://play.google.com/store/apps/details?id=com.ted.android&utm_medium=email&utm_source=ted_talks_daily_newsletter&utm_campaign=daily&user_email_address=f325af12e52a26ff58bb171ffc448307',
    mixpanelContext: 'download_google-play'
  },
  {
    label: 'Apple Store',
    Icon: 'Apple',
    url: 'https://apps.apple.com/app/apple-store/id376183339?user_email_address=f325af12e52a26ff58bb171ffc448307&utm_campaign=daily&utm_medium=email&utm_source=ted_talks_daily_newsletter',
    mixpanelContext: 'download_apple-store'
  }
];

const DownloadTedApp = (): React.ReactNode => {
  return (
    <div>
      <Text className="bold mb-6 font-bold text-white" size="m">
        Download the TED App
      </Text>
      <div className="flex flex-row gap-5">
        {footerAppStoreLinks.map(({ label, Icon, url, mixpanelContext }) => (
          <Link
            key={url}
            href={url}
            data-mixpanel-context={mixpanelContext}
            onClick={() => {
              mixpanel.track('global_footer_click', {
                event_interaction_context: mixpanelContext,
                system_language: getSystemLanguage(),
                url
              });
            }}
          >
            <AppStoreIcon
              variant={Icon as 'Google' | 'Apple'}
              ariaLabel={label}
              className={'h-11 drop-shadow-sm'}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DownloadTedApp;
