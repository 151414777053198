import { useMemo } from 'react';
import { LinesBlockProps } from 'components/skeleton-loader';

// "random" 80%+ values
const LINE_LENGTHS = ['91%', '99%', '94%', '89%', '81%'];

const Title = ({ className = '', numberOfLines = 5 }: LinesBlockProps) => {
  const renderLines = useMemo(() => {
    const lines = [];
    for (let i = 0; i < numberOfLines; i += 1) {
      // index will always be between 0 and LINE_LENGTHS.length - 1;
      const lengthIndex = i % LINE_LENGTHS.length;
      const length = LINE_LENGTHS[lengthIndex];
      const line = (
        <div
          key={i}
          className="mt-2 h-5 bg-gray-300 xl:h-8"
          style={{ width: length }}
        />
      );
      lines.push(line);
    }
    return lines;
  }, [numberOfLines]);

  return (
    <div className={`animate-pulse ${className}`}>
      <div className="mb-3 h-2 w-2/6 bg-gray-300" />
      {renderLines}
    </div>
  );
};

export default Title;
