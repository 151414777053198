import cx from 'classnames';
import { Button, Icon } from 'components/@tedui';
import { isValidEmail } from 'lib/util';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import { Text } from 'components/typography';

import { Honeypot } from 'components/footer/Honeypot';
import NewsletterFormProps from './NewsletterForm.props';

const ERROR_MESSAGE = 'Enter a valid email address';

function NewsletterForm({
  onSubscribe,
  location,
  error
}: NewsletterFormProps): React.ReactNode {
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [isValid, setIsValid] = useState(!error);
  const [honeypotChecked, setHoneypotChecked] = useState(false);

  useEffect(() => {
    if (error) {
      setIsValid(false);
    }
  }, [error]);

  const isOnFooter = location === 'footer';

  const wrapperClassNames = cx('mb-3 flex rounded-sm bg-white', {
    'border-thicker border-black': !isOnFooter,
    'flex-row justify-between border-thin border-white': isOnFooter
  });

  const inputClassNames = cx(
    'flex h-11 w-full flex-1 rounded-sm border-none bg-white',
    {
      'focus:placeholder-transparent sm:pl-8 sm:text-tui-sm md:pl-10':
        !isOnFooter,
      'p-4': isOnFooter
    }
  );

  const buttonWrapperClassNames = cx(
    'flex justify-center rounded-r-sm align-middle',
    {
      'border-l-thicker': !isOnFooter,
      'h-11 bg-black': isOnFooter
    }
  );

  const buttonClassNames = cx({
    'h-auto px-6': !isOnFooter,
    'h-11 px-10': isOnFooter
  });

  const onChange = useCallback(
    ({ target }) => {
      if (target.value) {
        if (!isValid) {
          setIsValid(true);
        }
        const debouncedSave = debounce(() => {
          setNewsletterEmail(target.value);
        }, 1000);
        debouncedSave();
      } else {
        setNewsletterEmail(target.value);
      }
    },
    [setNewsletterEmail, isValid]
  );

  const handleSubscribe = e => {
    e.preventDefault();
    if (!honeypotChecked) {
      const trimmedEmail = newsletterEmail.trim();

      if (!isValidEmail(trimmedEmail)) {
        setIsValid(false);
        return null;
      }
      setIsValid(true);
      onSubscribe(trimmedEmail);
    }
  };

  return (
    <form data-testid="NewsletterForm_TESTID" onSubmit={handleSubscribe}>
      <div className={wrapperClassNames}>
        <Honeypot onGrab={setHoneypotChecked} />
        <div className="relative w-full">
          {!isOnFooter && (
            <Icon
              iconName="mail"
              className="absolute left-3 top-1/2 mr-2 -translate-y-1/2 transform text-gray-500 sm:text-tui-sm md:text-tui-xl"
            />
          )}
          <input
            onChange={onChange}
            placeholder="What's your email?"
            id="newsletter-email"
            className={inputClassNames}
            type="email"
          />
          {!isValid && (
            <Icon
              iconName="alert-triangle"
              className="absolute right-2 top-1/2 mr-2 -translate-y-1/2 transform text-tui-xl text-systemInfo-error-onDark"
            />
          )}
        </div>
        <div className={buttonWrapperClassNames}>
          <Button
            UNSAFE_className={buttonClassNames}
            text="Subscribe"
            isDarkBackground={isOnFooter}
            isFullWidth={false}
            testId="Footer_Newsletter_Subscribe"
            variant="tertiary"
            disabled={!isValid}
            type="submit"
          />
        </div>
      </div>
      {!isValid && (
        <Text className="mb-2 text-systemInfo-error-onDark" size="m">
          {ERROR_MESSAGE}
        </Text>
      )}
    </form>
  );
}

export default NewsletterForm;
