import { getOptinDate } from './utils';

export const SOURCES = {
  HOMEPAGE_MODULE: 'homepage_newsletter_module',
  NEWSLETTER_MODULE: 'newsletter-page'
};

export const MASTER_LIST = {
  'Master List': 1
};

export const TTD_VARS = {
  ted_talks_daily_newsletter_frequency: 'Daily',
  ted_talks_daily_newsletter_subscriber: true,
  ted_talks_daily_newsletter_optin_date: getOptinDate()
};

export const TTR_VARS = {
  ted_recommends_newsletter_frequency: 'Weekly',
  ted_recommends_newsletter_subscriber: true,
  ted_recommends_newsletter_optin_date: getOptinDate()
};

export const TED_ED_DAILY_VARS = {
  ted_ed_daily_newsletter_subscriber: true
};

export const TED_ED_WEEKLY_VARS = {
  ted_ed_weekly_newsletter_frequency: 'Weekly',
  ted_ed_weekly_newsletter_subscriber: true,
  ted_ed_weekly_newsletter_optin_date: getOptinDate()
};

export const TED_COUNTDOWN_MONTHLY_VARS = {
  countdown_newsletter_frequency: 'Monthly',
  countdown_newsletter_subscriber: true,
  countdown_newsletter_optin_date: getOptinDate()
};

export const TED_TECH_BIWEEKLY_VARS = {
  ted_talks_tech_newsletter_frequency: 'Biweekly',
  ted_talks_tech_newsletter_subscriber: true,
  ted_talks_tech_newsletter_optin_date: getOptinDate()
};

export const RESET_NEWSLETTER: { [key: string]: string | boolean | number } = {
  countdown_newsletter_frequency: 0,
  countdown_newsletter_subscriber: false,
  countdown_newsletter_optin_date: '1900-01-01',
  ted_ed_weekly_newsletter_frequency: 0,
  ted_ed_weekly_newsletter_optin_date: '1900-01-01',
  ted_ed_weekly_newsletter_subscriber: false,
  ted_recommends_newsletter_frequency: 0,
  ted_recommends_newsletter_subscriber: false,
  ted_recommends_newsletter_optin_date: '1900-01-01',
  ted_talks_daily_newsletter_frequency: 0,
  ted_talks_daily_newsletter_subscriber: false,
  ted_talks_daily_newsletter_optin_date: '1900-01-01',
  ted_talks_tech_newsletter_frequency: 0,
  ted_talks_tech_newsletter_subscriber: false,
  ted_talks_tech_newsletter_optin_date: '1900-01-01',
  ted_talks_daily_newsletter_source: null,
  ted_recommends_newsletter_source: null,
  ted_ed_newsletter_source: null,
  countdown_newsletter_source: null,
  ted_talks_tech_newsletter_source: null
};

export const RIBBON_SUBSCRIPTION_MAP = {
  TEDTalksDaily: TTD_VARS,
  TEDEd: TED_ED_WEEKLY_VARS,
  TEDRecommends: TTR_VARS,
  TEDCountdown: TED_COUNTDOWN_MONTHLY_VARS,
  TEDTech: TED_TECH_BIWEEKLY_VARS
};

export const RIBBON_SUBSCRIPTION_SOURCE_MAP = (
  newsletterName: string,
  source: string
): Record<string, string> => {
  switch (newsletterName) {
    case 'TEDTalksDaily':
      return {
        ted_talks_daily_newsletter_source: source
      };
    case 'TEDEd':
      return {
        ted_ed_newsletter_source: source
      };
    case 'TEDRecommends':
      return {
        ted_recommends_newsletter_source: source
      };
    case 'TEDCountdown':
      return {
        countdown_newsletter_source: source
      };
    case 'TEDTech':
      return {
        ted_talks_tech_newsletter_source: source
      };
    default:
      return {};
  }
};
