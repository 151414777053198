import { Menu, MenuButton, MenuList } from 'components/shared/Menu';
import { RefObject } from 'react';
import UserImage from './UserImage';
import UserLinkList from './UserLinkList';
import { Badge } from 'components/@tedui';

import {
  LinkList,
  LinkListType,
  type UserData
} from './UserLinkList/UserLinkList.props';

type UserMenuProps = {
  userButtonClasses: string;
  userImageClasses: string;
  userButtonRef: RefObject<HTMLButtonElement | null>;
  isLoading: boolean;
  user: UserData;
  userLinkList: LinkList[];
};

const UserMenu = ({
  userButtonClasses,
  userImageClasses,
  userButtonRef,
  isLoading,
  user,
  userLinkList
}: UserMenuProps): React.ReactNode => {
  return (
    <div className="navigation-header-avatar-wrapper ml-05 hidden lg:flex">
      <Menu>
        <MenuButton
          className={userButtonClasses}
          ref={userButtonRef}
          aria-label="Profile"
        >
          <UserImage
            className={userImageClasses}
            isLoading={isLoading}
            userImage={user?.avatar?.url || user?.avatar?.generatedUrl || ''}
          />
          <Badge
            backgroundColor="red-500"
            testId="avatar-desktop-badge"
            text="New"
          />
        </MenuButton>
        <MenuList
          portal={false}
          className="absolute right-0 top-full z-10 rounded-md border-thin border-gray-300 bg-white p-8 shadow-xl outline-none dark:border-none dark:bg-gray-800"
        >
          <UserLinkList
            type={LinkListType.Menu}
            linkList={userLinkList}
            isLoading={isLoading}
            userData={user}
          />
        </MenuList>
      </Menu>
    </div>
  );
};

export default UserMenu;
