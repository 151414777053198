import { Badge } from 'components/@tedui';
import { FormattedMessage } from 'react-intl';
import { PAGE_TYPES } from '.';

export const userLinks = [
  {
    id: 'learning-hub',
    label: <FormattedMessage defaultMessage="Learning Hub" />,
    link: '/dashboard/learning-hub',
    isZenithLink: true,
    mixpanelContext: 'profile_learning-hub',
    badge: (
      <Badge backgroundColor="red-500" testId="learning-hub-badge" text="New" />
    )
  },
  {
    id: 'watch-history',
    label: <FormattedMessage defaultMessage="Watch History" />,
    link: '/dashboard/watch-history',
    isZenithLink: true,
    pageType: PAGE_TYPES.ZENITH,
    mixpanelContext: 'profile_my-library'
  },
  {
    id: 'conferences',
    label: <FormattedMessage defaultMessage="Conferences" />,
    link: 'https://www.ted.com/dashboard/conferences',
    isZenithLink: false,
    pageType: PAGE_TYPES.PAGE_BUILDER,
    featureFlag: 'profile_conferences',
    mixpanelContext: 'profile_conferences'
  },
  {
    id: 'tedx-apply',
    label: <FormattedMessage defaultMessage="TEDx events" />,
    link: 'https://tedx-apply.ted.com/manage',
    isZenithLink: false,
    pageType: PAGE_TYPES.EXTERNAL,
    mixpanelContext: 'profile_tedx-apply'
  },
  {
    id: 'ted-ed-info',
    label: <FormattedMessage defaultMessage="Your TED-Ed activity" />,
    link: 'https://ed.ted.com/u/lessons',
    isZenithLink: false,
    pageType: PAGE_TYPES.EXTERNAL,
    mixpanelContext: 'profile_ted-ed-info'
  }
];

export default userLinks;
