import type { JSX } from 'react';

type FacebookIconProps = {
  className?: string;
  variant: 'circle' | 'circle-white';
  label?: string;
};

const FacebookIcon = (props: FacebookIconProps): JSX.Element => {
  const { className, variant, label } = props;
  switch (variant) {
    case 'circle':
      return (
        <svg
          className={className || ''}
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM26.5025 25.0545V38.1118H21.1V25.055H18.401V20.5554H21.1V17.8539C21.1 14.1831 22.6241 12.0003 26.9542 12.0003H30.559V16.5004H28.3057C26.6202 16.5004 26.5086 17.1292 26.5086 18.3028L26.5025 20.5549H30.5846L30.1069 25.0545H26.5025Z"
            fill="#425893"
          />
        </svg>
      );
    case 'circle-white':
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-label={label}
        >
          <g clip-path="url(#clip0_1694_78366)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM17.668 16.7028V25.4076H14.0663V16.7031H12.2669V13.7034H14.0663V11.9024C14.0663 9.4552 15.0824 8 17.9691 8H20.3723V11.0001H18.8701C17.7464 11.0001 17.672 11.4193 17.672 12.2017L17.668 13.7031H20.3893L20.0709 16.7028H17.668Z"
              fill="#F6F6F6"
            />
          </g>
          <defs>
            <clipPath id="clip0_1694_78366">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <></>;
  }
};
export default FacebookIcon;
