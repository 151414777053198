import type { JSX } from 'react';

type ChevronIconProps = {
  className?: string;
  variant: 'default' | 'left' | 'link' | 'right' | 'small';
};

const ChevronIcon = (props: ChevronIconProps): JSX.Element => {
  const { className, variant } = props;
  switch (variant) {
    case 'left':
      return (
        <svg
          className={className || ''}
          viewBox="0 0 10 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 17.5835L1 9.5835L9 1.5835"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'right':
      return (
        <svg
          className={className || ''}
          viewBox="0 0 10 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 17.5835L9 9.5835L1 1.5835"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'link':
      return (
        <svg
          className={className || ''}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18L15 12L9 6"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          className={className || ''}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 4.5L6 7.5L9 4.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg className={className || ''} viewBox="0 0 15 10">
          <path
            d="M7.44027 7.2661l5.62449-5.98417c.4163-.44257 1.08946-.44257 1.50134 0 .41187.44258.41187 1.15823 0 1.6008L8.19315 9.6626c-.40301.42845-1.0496.43787-1.4659.03296L.31 2.88744C.10186 2.66615 0 2.37424 0 2.08704s.10186-.57911.31001-.8004c.4163-.44258 1.08947-.44258 1.50134 0L7.44027 7.2661z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );
  }
};
export default ChevronIcon;
