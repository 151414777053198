import { crush } from '@tedconf/js-crushinator-helpers';

import { useNavUserInformationQuery } from 'api';
import mixpanel from 'lib/analytics/mixpanel';
import { getSystemLanguage } from 'lib/analytics/mixpanel-helpers';
import { useAuthenticated } from 'lib/auth';
import { useDidMount } from 'lib/hooks/useDidMount';
import { createLogger } from 'lib/logging';

import Avatar from 'components/avatar';
import ChevronLink from 'components/chevron-link';
import { Text } from 'components/typography';

import { FooterPromoItems } from './index';

type FooterPromoProps = {
  promoData: FooterPromoItems;
};

const FooterPromo = ({
  promoData
}: FooterPromoProps): React.ReactNode | null => {
  const isLoggedIn = useAuthenticated();
  const { data, loading, error } = useNavUserInformationQuery({
    ssr: false,
    skip: !isLoggedIn
  });

  const isMounted = useDidMount();

  if (loading || !isMounted) {
    return null;
  }
  if (error) {
    const log = createLogger('Footer Promo');
    log.error(error);
    return null;
  }

  const { membership, membershipDashboard } = promoData;
  const avatar = data?.viewer?.avatar?.url
    ? data.viewer.avatar.url
    : data?.viewer?.avatar?.generatedUrl;

  return data?.viewer?.isMember ? (
    <div className="mb-10 md:mb-0 xl:mb-12">
      <div className="mb-6 flex items-center">
        <div className="mr-3 h-8 w-8">
          <Avatar
            alt="user avatar"
            className="w-8 rounded-full"
            src={crush(avatar || '')}
            imgWidth={32}
          />
        </div>
        <ChevronLink
          className="items-center text-white hover:text-gray-700"
          url={membershipDashboard.url || ''}
          text={membershipDashboard.label}
          onClick={() => {
            mixpanel.track('global_footer_click', {
              event_interaction_context: membershipDashboard.mixpanelContext,
              system_language: getSystemLanguage()
            });
          }}
        />
      </div>
      <Text size="m" className="text-white">
        {membershipDashboard.text}
      </Text>
    </div>
  ) : (
    <div className="mb-10 md:mb-0 xl:mb-12">
      <div className="mb-6">
        <ChevronLink
          className="items-center text-white hover:text-gray-700"
          url={membership.url || ''}
          text={membership.label}
          onClick={() => {
            mixpanel.track('global_footer_click', {
              event_interaction_context: membership.mixpanelContext,
              system_language: getSystemLanguage()
            });
          }}
        />
      </div>
      <Text size="m" className="text-white">
        {membership.text}
      </Text>
    </div>
  );
};

export default FooterPromo;
