import { useMemo } from 'react';
import { LinesBlockProps } from 'components/skeleton-loader';

// "random" 90%+ values
const LINE_LENGTHS = ['94%', '97%', '99%', '93%', '95%'];

const Content = ({ numberOfLines = 5, className = '' }: LinesBlockProps) => {
  const renderLines = useMemo(() => {
    const lines = [];
    for (let i = 0; i < numberOfLines; i += 1) {
      // index will always be between 0 and LINE_LENGTHS.length - 1;
      const lengthIndex = i % LINE_LENGTHS.length;
      const length = LINE_LENGTHS[lengthIndex];
      const line = (
        <div
          key={i}
          className="mt-2 h-3 bg-gray-300 first:mt-0"
          style={{ width: length }}
        />
      );
      lines.push(line);
    }
    return lines;
  }, [numberOfLines]);

  return <div className={`animate-pulse ${className}`}>{renderLines}</div>;
};

export default Content;
