import type { JSX } from 'react';

type InstagramIconProps = {
  className?: string;
  variant: 'circle-white';
  label?: string;
};

const InstagramIcon = (props: InstagramIconProps): JSX.Element => {
  const { className, variant, label } = props;
  if (variant === 'circle-white') {
    return (
      <svg
        className={className || ''}
        aria-label={label}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1694_78328)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM12.4822 7.51822C13.3924 7.4768 13.6833 7.46667 16.0008 7.46667H15.9981C18.3164 7.46667 18.6062 7.4768 19.5164 7.51822C20.4248 7.55982 21.0453 7.70365 21.5893 7.91467C22.1511 8.13245 22.6258 8.42401 23.1004 8.89868C23.5751 9.373 23.8667 9.84909 24.0853 10.4103C24.2951 10.9529 24.4391 11.573 24.4818 12.4815C24.5227 13.3917 24.5333 13.6826 24.5333 16.0001C24.5333 18.3176 24.5227 18.6078 24.4818 19.518C24.4391 20.4261 24.2951 21.0464 24.0853 21.5891C23.8667 22.1502 23.5751 22.6263 23.1004 23.1006C22.6263 23.5753 22.1509 23.8675 21.5898 24.0855C21.0469 24.2965 20.4261 24.4404 19.5176 24.482C18.6074 24.5234 18.3174 24.5335 15.9997 24.5335C13.6824 24.5335 13.3917 24.5234 12.4815 24.482C11.5732 24.4404 10.9529 24.2965 10.41 24.0855C9.84909 23.8675 9.373 23.5753 8.89886 23.1006C8.42437 22.6263 8.13281 22.1502 7.91467 21.5889C7.70382 21.0464 7.56 20.4263 7.51822 19.5178C7.47698 18.6076 7.46667 18.3176 7.46667 16.0001C7.46667 13.6826 7.47733 13.3915 7.51804 12.4813C7.55893 11.5732 7.70294 10.9529 7.91449 10.4102C8.13316 9.84909 8.42472 9.373 8.89939 8.89868C9.37371 8.42419 9.8498 8.13263 10.4111 7.91467C10.9536 7.70365 11.5737 7.55982 12.4822 7.51822Z"
            fill="#F6F6F6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2355 9.00445C15.3841 9.00422 15.544 9.00429 15.7166 9.00437L16.001 9.00445C18.2794 9.00445 18.5495 9.01263 19.4492 9.05352C20.2812 9.09156 20.7328 9.23059 21.0336 9.34739C21.4318 9.50206 21.7157 9.68695 22.0142 9.98562C22.3129 10.2843 22.4978 10.5687 22.6528 10.967C22.7696 11.2674 22.9088 11.719 22.9467 12.551C22.9876 13.4505 22.9964 13.7208 22.9964 15.9981C22.9964 18.2755 22.9876 18.5457 22.9467 19.4453C22.9086 20.2773 22.7696 20.7288 22.6528 21.0293C22.4981 21.4275 22.3129 21.7111 22.0142 22.0096C21.7155 22.3082 21.432 22.4931 21.0336 22.6478C20.7331 22.7651 20.2812 22.9038 19.4492 22.9418C18.5496 22.9827 18.2794 22.9916 16.001 22.9916C13.7224 22.9916 13.4524 22.9827 12.5528 22.9418C11.7208 22.9034 11.2692 22.7644 10.9682 22.6476C10.57 22.4929 10.2856 22.3081 9.98689 22.0094C9.68822 21.7107 9.50333 21.427 9.34831 21.0286C9.23151 20.7281 9.09231 20.2766 9.05444 19.4446C9.01355 18.545 9.00537 18.2748 9.00537 15.996C9.00537 13.7172 9.01355 13.4484 9.05444 12.5488C9.09248 11.7168 9.23151 11.2653 9.34831 10.9645C9.50298 10.5662 9.68822 10.2818 9.98689 9.98313C10.2856 9.68446 10.57 9.49957 10.9682 9.34455C11.269 9.22721 11.7208 9.08854 12.5528 9.05032C13.34 9.01476 13.6451 9.0041 15.2355 9.00232V9.00445ZM20.5561 10.4214C19.9907 10.4214 19.5321 10.8795 19.5321 11.445C19.5321 12.0103 19.9907 12.469 20.5561 12.469C21.1214 12.469 21.5801 12.0103 21.5801 11.445C21.5801 10.8797 21.1214 10.421 20.5561 10.421V10.4214ZM11.6188 16.0001C11.6188 13.58 13.5808 11.6179 16.0009 11.6178C18.421 11.6178 20.3826 13.58 20.3826 16.0001C20.3826 18.4202 18.4212 20.3815 16.0011 20.3815C13.5809 20.3815 11.6188 18.4202 11.6188 16.0001Z"
            fill="#F6F6F6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0007 13.1556C17.5716 13.1556 18.8452 14.429 18.8452 16.0001C18.8452 17.5709 17.5716 18.8445 16.0007 18.8445C14.4297 18.8445 13.1562 17.5709 13.1562 16.0001C13.1562 14.429 14.4297 13.1556 16.0007 13.1556V13.1556Z"
            fill="#F6F6F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_1694_78328">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return <></>;
};
export default InstagramIcon;
