import { NavUserInformationQuery } from 'api';

export type UserData = NonNullable<NavUserInformationQuery['viewer']>;

export enum LinkListType {
  Modal = 'MODAL',
  Menu = 'Menu'
}

export type LinkList = {
  id: string;
  badge?: React.ReactNode;
  label: React.ReactNode;
  link: string;
  pageType?: string;
  mixpanelContext?: string;
  isZenithLink: boolean;
  featureFlag?: string;
};

type UserLinkListProps = {
  linkList: LinkList[];
  type: LinkListType;
  isLoading: boolean;
  userData: Pick<UserData, 'name' | 'avatar' | 'isMember'>;
};

export default UserLinkListProps;
