import type { JSX } from 'react';

type LinkedinIconProps = {
  className?: string;
  variant: 'circle-blue' | 'circle-white';
  label?: string;
};

const LinkedinIcon = (props: LinkedinIconProps): JSX.Element => {
  const { className, variant, label } = props;
  if (variant === 'circle-blue') {
    return (
      <svg
        className={className || ''}
        xmlns="http://www.w3.org/2000/svg"
        height="72"
        viewBox="0 0 72 72"
        width="72"
      >
        <g fill="none" fillRule="evenodd">
          <path
            d="M36,72 L36,72 C55.882251,72 72,55.882251 72,36 L72,36 C72,16.117749 55.882251,-3.65231026e-15 36,0 L36,0 C16.117749,3.65231026e-15 -2.4348735e-15,16.117749 0,36 L0,36 C2.4348735e-15,55.882251 16.117749,72 36,72 Z"
            fill="#2867B2"
          />
          <path
            d="M59,57 L49.959375,57 L49.959375,41.6017895 C49.959375,37.3800228 48.3552083,35.0207581 45.0136719,35.0207581 C41.3785156,35.0207581 39.4792969,37.4759395 39.4792969,41.6017895 L39.4792969,57 L30.7666667,57 L30.7666667,27.6666667 L39.4792969,27.6666667 L39.4792969,31.6178624 C39.4792969,31.6178624 42.0989583,26.7704897 48.3236979,26.7704897 C54.5455729,26.7704897 59,30.5699366 59,38.4279486 L59,57 Z M20.372526,23.8257036 C17.4048177,23.8257036 15,21.4020172 15,18.4128518 C15,15.4236864 17.4048177,13 20.372526,13 C23.3402344,13 25.7436198,15.4236864 25.7436198,18.4128518 C25.7436198,21.4020172 23.3402344,23.8257036 20.372526,23.8257036 Z M15.8736979,57 L24.958724,57 L24.958724,27.6666667 L15.8736979,27.6666667 L15.8736979,57 Z"
            fill="#FFF"
          />
        </g>
      </svg>
    );
  }
  if (variant === 'circle-white') {
    return (
      <svg
        className={className || ''}
        aria-label={label}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1694_78301)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM9.49621 11.7649C10.7605 11.7649 11.5479 10.9279 11.5479 9.8818C11.5244 8.8136 10.7605 8 9.5201 8C8.27969 8 7.46875 8.8136 7.46875 9.8818C7.46875 10.9279 8.25572 11.7649 9.47304 11.7649H9.49621ZM11.3086 24.1465V13.252H7.68261V24.1465H11.3086ZM24.3872 17.9011L24.3871 24.1477H20.7613V18.3191C20.7613 16.8551 20.2366 15.8561 18.924 15.8561C17.9222 15.8561 17.3256 16.5296 17.0635 17.1801C16.9676 17.4132 16.9441 17.738 16.9441 18.0636V24.148H13.3177C13.3177 24.148 13.3655 14.2758 13.3177 13.2535H16.9441V14.7966C17.4253 14.0548 18.2873 12.9974 20.2124 12.9974C22.5984 12.9974 24.3872 14.5548 24.3872 17.9011Z"
            fill="#F6F6F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_1694_78301">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return <></>;
};
export default LinkedinIcon;
