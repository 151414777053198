import { Text } from 'components/typography';
import IconedTitleProps from './IconedTitle.props';

const IconedTitle = ({
  icon,
  label,
  color = 'text-black',
  suffix
}: IconedTitleProps): React.ReactNode => {
  return (
    <div className={`flex flex-row align-middle ${color}`}>
      {icon}
      <Text className="bold pt-1 font-bold" size="m">
        {label}
      </Text>
      {suffix}
    </div>
  );
};

export default IconedTitle;
