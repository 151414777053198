// There can be four types of links that a user can navigate to
// PRISMIC = New CMS and can house old legacy pages that previously existed on Page Builder/Road Runner
// ZENITH = NEXT JS Internal pages
// PAGE_BUILDER = Pages that still exist on RoadRunner and are built in Page Builder. These are planned to all be deprecated/removed
// EXTERNAL = Not Located on the TED.com domain
export const PAGE_TYPES = {
  PRISMIC: 'prismic',
  ZENITH: 'zenith',
  PAGE_BUILDER: 'page_builder',
  EXTERNAL: 'external'
};
