import cx from 'classnames';
import { useEffect } from 'react';

import Navigation from 'components/Navigation';
import FooterNav from 'components/footer';
import TEDHead from 'components/ted-head';
import { useLayoutStore } from './store';

type LayoutProps = {
  addMinHeight?: boolean;
  addMaxHeight?: boolean;
  children: React.ReactNode;
  pageTitle?: string;
  /** Default: 'gray' */
  backgroundColor?: 'white' | 'gray';
  showMenuLinks?: boolean;
  showLinksFooter?: boolean;
  isDarkMode?: boolean;
};

export function Layout({
  addMinHeight = true,
  addMaxHeight = false,
  children,
  pageTitle,
  backgroundColor = 'gray',
  showMenuLinks = true,
  showLinksFooter = true,
  isDarkMode = false
}: LayoutProps): React.ReactNode {
  const { setShowMenuLinks, setShowLinksFooter } = useLayoutStore();

  useEffect(() => {
    setShowMenuLinks(showMenuLinks);
    setShowLinksFooter(showLinksFooter);

    return () => {
      setShowMenuLinks(true);
      setShowLinksFooter(true);
    };
  }, [setShowMenuLinks, setShowLinksFooter, showMenuLinks, showLinksFooter]);

  return (
    <>
      <TEDHead pageTitle={pageTitle} />
      <div className="flex min-h-screen w-full flex-col bg-white">
        <header className="z-30 -mt-14 translate-y-full">
          <Navigation isDarkMode={isDarkMode} />
        </header>
        <main
          id="maincontent"
          data-testid="main-content"
          className={cx(`relative flex w-full flex-grow flex-col pt-14`, {
            'bg-black': isDarkMode,
            'bg-white': backgroundColor === 'white',
            'bg-gray-50': backgroundColor === 'gray',
            'min-h-screen': addMinHeight,
            'max-h-screen': addMaxHeight
          })}
        >
          {children}
        </main>
        <footer className={'flex w-full'}>
          <FooterNav />
        </footer>
      </div>
    </>
  );
}
